import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import TeamMemberAvatar from "../avatars/TeamMemberAvatar";
import { convertSecondsToTime } from "../../../utils/convertTime";
import {
  PauseTimer,
  ResumeTimer,
  UpdateTimer,
} from "../../../store/actions/timerAction";
import PlayIcon from "../../Icons/PlayIcon";
import PauseIcon from "../../Icons/PauseIcon";

function TimeTrackerCard({ user = null, setIsStartTimerModalOpen, is_task }) {
  const dispatch = useDispatch();

  const { isRunning, isStarted, time } = useSelector((state) => state.timer);

  useEffect(() => {
    let id = setInterval(() => dispatch(UpdateTimer()), 1000);
    return () => clearInterval(id);
  }, [dispatch]);

  if (!isStarted && !isRunning) {
    return null;
  }

  return (
    <div
      className={`flex list-none rounded-[60px] border items-center justify-between text-Neutral900 transition-all duration-300 ease-in-out ${
        is_task
          ? "px-1 pr-2 py-1"
          : "hover:bg-white hover:border-Neutral500 p-[10px] mt-[5px]"
      }`}
    >
      {!is_task && (
        <div className="flex items-center justify-between">
          {user && <TeamMemberAvatar user={user} />}
          <div className="flex flex-col items-start justify-start ml-2 mr-2 w-max">
            <p
              className="mb-0 text-base font-bold leading-[22px] text-Neutral900 truncate w-[130px]"
              data-tip={user?.time_tracker?.title}
              data-for="timerTooltip"
            >
              {user?.time_tracker?.title}
              <ReactTooltip
                place="bottom"
                type="dark"
                effect="solid"
                className="tooltip"
                id="timerTooltip"
              />
            </p>
            {user?.time_tracker?.timer_category && (
              <div className="flex items-center">
                <span className=" mt-1 w-[8px] h-[8px] bg-Green400 mr-[7px] rounded-full"></span>
                <p
                  className="mt-1 uppercase leading-[16px] text-xs truncate w-[100px] xl:w-full"
                  data-tip={user?.time_tracker?.timer_category}
                  data-for="timer2tooltip"
                >
                  {user?.time_tracker?.timer_category}
                  <ReactTooltip
                    place="bottom"
                    type="dark"
                    effect="solid"
                    className="tooltip"
                    id="timer2tooltip"
                  />
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="flex items-center ml-[32px]">
        <p className="text-lg font-medium mr-[19px] leading-[25px] text-Neutral900">
          {convertSecondsToTime(time.value)}
        </p>
        {isRunning ? (
          <>
            <button
              className="min-w-[48px] h-[48px] bg-Neutral200 rounded-full flex items-center justify-center mr-[10px] hover:bg-Neutral300"
              aria-label="Pause timer"
              onClick={() => dispatch(PauseTimer(time?.value, user?.time_tracker?.project))}
            >
              <PauseIcon />
            </button>{" "}
            <button
              className="min-w-[48px] h-[48px] bg-Red400 rounded-full flex items-center justify-center relative after:content-[''] after:absolute after:w-[16px] after:h-[16px] after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%] after:bg-Neutral000 after:rounded-[1px]"
              aria-label="Stop timer"
              onClick={() => setIsStartTimerModalOpen(true)}
            />
          </>
        ) : (
          <button
            className="min-w-[48px] h-[48px] bg-Green400 rounded-full flex items-center justify-center"
            aria-label="Start timer"
            onClick={() => dispatch(ResumeTimer(user?.time_tracker?.project))}
          >
            <PlayIcon color="#FFF" />
          </button>
        )}
      </div>
      {/* <div className="flex items-center rounded-[6px] px-2 py-[6px] ml-0 bg-Neutral200">
        <span className="inline-block font-semibold text-xs leading-[100%] mr-1">
          $ {member?.rate}
        </span>
        <span className="inline-block text-Neutral600 text-[8px] font-bold pt-[1px]">
          / Hr
        </span>
      </div> */}
    </div>
  );
}

export default TimeTrackerCard;
