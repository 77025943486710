import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import CMLogo from "../../../assets/Images/Logo.svg";
import { useWhiteLabelSettings } from "../../../contexts/WhiteLabelProvider";
import {
  getIdentityAction,
  UpdateIdentityAction,
} from "../../../store/actions/WhiteLabel/brandIdentityAction";
import { cn } from "../../../utils";
import LoadingIcon from "../../Icons/LoadingIcon";
import UploadIcon from "../../Icons/UploadIcon";
import FeaturesModal from "../../White-Label/FeaturesModal";

function BrandIdentityCard() {
  const fileInputRef = useRef(null);

  const { settings } = useWhiteLabelSettings();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedInUser = useSelector((state) => state?.auth?.user);
  const brandIdentityData = useSelector((state) => state?.brandIdentity);
  const [state, setState] = useState(brandIdentityData);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [loading, setLoading] = useState({
    bg_color: false,
    accent_color: false,
    logo: false,
    companyName: false,
  });

  // const isWhiteLabelSubscriber = true; // Testing
  const isWhiteLabelSubscriber = settings.get("isWhiteLabelSubscriber");

  useEffect(() => {
    dispatch(getIdentityAction({ user: loggedInUser?._id }));
  }, [dispatch, loggedInUser?._id]);

  useEffect(() => {
    setState(brandIdentityData);
  }, [brandIdentityData]);

  const handleChangeColor = async (e) => {
    let value = e.target.value;
    const name = e.target.name;
   const defaultColor = name === "backgroundColor" ? "F0F0FF" : "6D6DF2"

    value = value.replace("#", "");
    if (/^[0-9A-F]{0,6}$/i.test(value)) {
      setState({
        ...brandIdentityData,
        [name]: `#${value?.toUpperCase()  || defaultColor}`,
      });
    }
  };

  const handleBlurColor = (e) => {
    let value = e.target.value;
    const name = e.target.name;
    value = value.replace("#", "");
    if (/^[0-9A-F]{0,6}$/i.test(value)) {
      const formData = new FormData();
      const defaultColor = name === "backgroundColor" ? "F0F0FF" : "6D6DF2"
      formData.append(name, `#${value?.toUpperCase()  || defaultColor}`);
      formData.append("user", loggedInUser?._id);
      dispatch(UpdateIdentityAction(formData, loggedInUser, name, setLoading));
    }
  };

  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("logo", file);
      formData.append("user", loggedInUser?._id);
      dispatch(
        UpdateIdentityAction(formData, loggedInUser, "logo", setLoading)
      );
    }
  };

  const handleCompanyNameChange = async (e) => {
    const { name, value } = e.target;
    const formData = new FormData();
    formData.append(name, value);
    formData.append("user", loggedInUser?._id);
    dispatch(UpdateIdentityAction(formData, loggedInUser, name, setLoading));
  };

  const handleFileInputClick = () => {
    if (isWhiteLabelSubscriber) {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      setShowUpgradeModal(true);
    }
  };

  const showUpgradeToWhiteLabelModal = (e) => {
    if (!isWhiteLabelSubscriber) {
      setShowUpgradeModal(true);
    }
  };

  return (
    <section className="w-full max-w-[830px] rounded-sm border border-Neutral300 p-4 md:p-7 bg-Neutral000">
      <h3 className="mb-10 text-xs font-bold tracking-widest uppercase text-Neutral600">
        Brand Identity
      </h3>

      <div className="flex flex-col gap-6">
        <div className="flex flex-col justify-between gap-2 md:items-center md:flex-row">
          <div className="space-y-1">
            <h4 className="text-sm font-bold text-Neutral900">
              Background Color
            </h4>
            <p className="text-sm text-Neutral700 max-w-[380px]">
              This will appear whenever we have used a background colour in
              client-facing screens.
            </p>
          </div>

          <div className="inline-flex gap-3">
            {loading.bg_color ? (
              <div className="flex items-center rounded size-12 bg-AccentMediumDark">
                <LoadingIcon />
              </div>
            ) : (
              <div
                className="rounded size-12"
                style={{ backgroundColor: state?.backgroundColor }}
              />
            )}
            <div
              className={cn(
                "relative px-3 gap-1.5 border rounded-md border-Neutral300 w-[100px] flex items-center flex-row-reverse hover:ring-2 ring-offset-1 focus-within:ring-2 ring-Neutral500",
                !isWhiteLabelSubscriber && "ring-Neutral300"
              )}
            >
              <input
                type="text"
                name="backgroundColor"
                className="w-full text-sm font-medium bg-transparent text-Neutral600 focus:outline-none placeholder:tracking-wider peer"
                placeholder="F0F0FF"
                maxLength="6"
                value={state?.backgroundColor?.replace("#", "")}
                onChange={handleChangeColor}
                onBlur={handleBlurColor}
                disabled={!isWhiteLabelSubscriber}
              />
              <span className="text-Neutral600 peer-focus:text-AccentRegular">
                #
              </span>
              {!isWhiteLabelSubscriber && (
                <div
                  className="absolute inset-0 z-10 hidden peer-disabled:block"
                  onClick={showUpgradeToWhiteLabelModal}
                >
                  {/* To show the upgrade modal (Do not remove) */}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-between gap-2 md:items-center md:flex-row">
          <div className="space-y-1">
            <h4 className="text-sm font-bold text-Neutral900">Accent Color</h4>
            <p className="text-sm text-Neutral700 max-w-[380px]">
              Customise the colour used for buttons and links used on pages
              shown to your clients.
            </p>
          </div>

          <div className="inline-flex gap-3">
            {loading.accent_color ? (
              <div className="flex items-center rounded size-12 bg-AccentMediumDark">
                <LoadingIcon />
              </div>
            ) : (
              <div
                className="rounded size-12"
                style={{ backgroundColor: state?.accentColor }}
              />
            )}
            <div
              className={cn(
                "relative px-3 gap-1.5 border rounded-md border-Neutral300 w-[100px] flex items-center flex-row-reverse hover:ring-2 ring-offset-1 focus-within:ring-2 ring-Neutral500",
                !isWhiteLabelSubscriber && "ring-Neutral300"
              )}
              onClick={showUpgradeToWhiteLabelModal}
            >
              <input
                type="text"
                name="accentColor"
                className="w-full text-sm font-medium bg-transparent text-Neutral600 focus:outline-none placeholder:tracking-wider peer"
                placeholder="732AFF"
                maxLength="6"
                value={state?.accentColor?.replace("#", "")}
                onChange={handleChangeColor}
                onBlur={handleBlurColor}
                disabled={!isWhiteLabelSubscriber}
              />
              <span className="text-Neutral600 peer-focus:text-AccentRegular">
                #
              </span>
              {!isWhiteLabelSubscriber && (
                <div
                  className="absolute inset-0 z-10 hidden peer-disabled:block"
                  onClick={showUpgradeToWhiteLabelModal}
                >
                  {/* To show the upgrade modal (Do not remove) */}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-between gap-2 md:items-center md:flex-row">
          <div className="space-y-1">
            <h4 className="text-sm font-bold text-Neutral900">Logo</h4>
            <p className="text-sm text-Neutral700 max-w-[380px]">
              Customise your brand identity with your own logo. We will use this
              in place of ours on all client-facing pages.
            </p>
          </div>

          <div className="inline-flex gap-3">
            {loading.logo ? (
              <div className="flex items-center overflow-hidden rounded size-12 bg-AccentMediumDark">
                <LoadingIcon />
              </div>
            ) : (
              <div className="overflow-hidden rounded size-12">
                {state?.brandLogoFile ? (
                  <img
                    src={URL.createObjectURL(state?.brandLogoFile)}
                    alt="Brand Logo"
                    className="object-cover size-full"
                  />
                ) : state?.brandLogoUrl ? (
                  <img
                    src={state?.brandLogoUrl}
                    alt="Brand Logo"
                    className="object-cover size-full"
                  />
                ) : (
                  <img
                    src={CMLogo}
                    alt="ClientManager Logo"
                    className="object-cover size-full"
                  />
                )}
              </div>
            )}
            <button
              className="relative px-3 text-Neutral600 gap-1.5 items-center border border-dashed rounded-md border-Neutral400 w-[100px] flex text-sm bg-Neutral100 cursor-pointer hover:bg-Neutral200 disabled:cursor-default disabled:hover:bg-Neutral100 group"
              onClick={handleFileInputClick}
            >
              <UploadIcon className="size-5" />
              <span className="text-Neutral600">Change</span>
              <input
                type="file"
                ref={fileInputRef}
                className="hidden peer"
                onChange={handleLogoChange}
                disabled={!isWhiteLabelSubscriber}
              />
            </button>
          </div>
        </div>

        <div className="flex flex-col justify-between gap-3 md:items-center md:flex-row md:gap-56">
          <div className="space-y-1">
            <h4 className="text-sm font-bold text-Neutral900">Company Name</h4>

            <p className="text-sm text-Neutral700 max-w-[340px]">
              Complete your brand identity with your own company name. We will
              use this in place of ours on client facing pages.
            </p>
          </div>

          <div
            className={cn(
              "relative px-3 py-2.5 gap-2 inline-flex items-center border ring-2 ring-transparent hover:ring-offset-2 rounded-md border-Neutral300 flex-grow focus-within:ring-AccentMediumLight hover:ring-AccentMediumLight",
              !isWhiteLabelSubscriber && "hover:ring-Neutral300 py-2.5"
            )}
          >
            <input
              type="text"
              name="companyName"
              className="w-full text-sm font-medium bg-transparent text-Neutral800 focus:outline-none placeholder:tracking-wider peer placeholder:text-Neutral500"
              placeholder="ClientManager"
              defaultValue={loggedInUser?.brandIdentity?.companyName}
              onBlur={handleCompanyNameChange}
            />
            {!isWhiteLabelSubscriber && (
              <div
                className="absolute inset-0 z-10 hidden peer-disabled:block"
                onClick={showUpgradeToWhiteLabelModal}
              >
                {/* To show the upgrade modal (Do not remove) */}
              </div>
            )}
          </div>
        </div>
      </div>

      <FeaturesModal
        isOpen={showUpgradeModal}
        setIsOpen={setShowUpgradeModal}
        data={{
          title: "Customise what your clients see...",
          features: [
            'Remove "Made with ClientManager"',
            "Use your company logo and domain",
            "Modify background & button colours",
            "And much more...",
          ],
          onViewPlanClick: () => {
            navigate("/white-label/plans");
          },
        }}
      />
    </section>
  );
}

export default BrandIdentityCard;
