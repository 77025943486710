const initialState = {
  project: {
    isLoading: true,
    data: [],
  },
  projectDetail: {
    isLoading: true,
    data: {},
  },
  projectHistory: [],
  archivedProjectList: {
    isLoading: true,
    data: [],
  },
  all_projects: {
    isLoading: true,
    data: {
      docs: [],
    },
  },
  all_collab_projects: {
    isLoading: true,
    data: [],
  },
  all_tasks_screens: {
    data: [],
  },
};

const removeDuplicate = (arr) => {
  return arr.filter((v, i, a) => a.findIndex((t) => t === v) === i);
};

const projectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_PROJECT_LIST_SUCCESS":
      let tempProjectList2 = state.all_projects.data.docs;
      if (!payload.isLoading) {
        if (payload?.data?.length) {
          payload.data.forEach((item) => {
            tempProjectList2 = tempProjectList2.map((project) => {
              if (project._id === item._id) {
                return item;
              }
              return project;
            });
            if (!tempProjectList2.includes(item)) {
              tempProjectList2.push(item);
            }
          });
        }
      }
      return {
        ...state,
        project: {
          ...state.project,
          ...payload,
        },
        all_projects: {
          ...state.all_projects,
          isLoading: payload.isLoading,
          data: {
            ...state.all_projects.data,
            docs: tempProjectList2,
          },
        },
      };
    case "SET_PROJECT_DETAIL":
      let tempProjectsList2 = state.all_projects.data.docs;
      tempProjectsList2 = tempProjectsList2.map((item) => {
        if (item._id === payload.data._id) {
          return payload.data;
        }
        return item;
      });
      let tempProjectList = state.project.data;
      tempProjectList = tempProjectList.map((item) => {
        if (item._id === payload.data._id) {
          return payload.data;
        }
        return item;
      });
      return {
        ...state,
        projectDetail: {
          ...state.projectDetail,
          ...payload,
        },
        all_projects: {
          ...state.all_projects,
          data: {
            ...state.all_projects.data,
            docs: tempProjectsList2,
          },
        },
        project: {
          ...state.project,
          data: tempProjectList,
        },
      };
    case "SET_PROJECT_HISTORY":
      return {
        ...state,
        projectHistory: payload,
      };
    case "GET_FILTER_PROJECT_TASKS_LENGTH_SUCCESS":
      let tempProjectList6 = state.all_projects.data.docs;
      tempProjectList6 = tempProjectList6.map((item) => {
        const findNew = payload.data.find((e) => e._id === item._id);
        if (findNew) {
          const total_tasks_by_column = Object.keys(
            item.total_tasks_by_column
          ).reduce((acc, key) => {
            if (findNew.total_tasks_by_column[key]) {
              acc[key] = findNew.total_tasks_by_column[key];
            } else {
              acc[key] = 0;
            }
            return acc;
          }, {});

          return (item = {
            ...item,
            total_tasks_by_column: {
              ...item.total_tasks_by_column,
              ...total_tasks_by_column,
            },
          });
        }
        return item;
      });
      return {
        ...state,
        all_projects: {
          ...state.all_projects,
          data: {
            ...state.all_projects.data,
            docs: tempProjectList6,
          },
        },
      };
    case "CREATE_PROJECT_COLUMN_SUCCESS":
      let tempProjectsList = state.all_projects.data.docs;
      tempProjectsList = tempProjectsList.map((item) => {
        if (item._id === payload.data._id) {
          return payload.data;
        }
        return item;
      });
      return {
        ...state,
        projectDetail: {
          ...state.projectDetail,
          data: payload.data.project,
        },
        all_projects: {
          ...state.all_projects,
          data: {
            ...state.all_projects.data,
            docs: tempProjectsList,
          },
        },
      };
    case "UPDATE_PROJECT_COLUMN_POSITION_SUCCESS":
      return {
        ...state,
        projectDetail: {
          ...state.projectDetail,
          data: payload.data,
        },
      };
    case "REMOVE_PROJECT_COLUMN_SUCCESS":
      return {
        ...state,
        projectDetail: {
          ...state.projectDetail,
          data: payload.data,
        },
      };
    case "CREATE_PROJECT_SCREEN_SUCCESS":
      const taskScreens = payload.data.task_screen;
      return {
        ...state,
        projectDetail: {
          ...state.projectDetail,
          data: payload.data,
        },
        all_tasks_screens: {
          data: removeDuplicate([...state.all_tasks_screens.data, ...taskScreens]),
        },
      };
    case "SET_ALL_PROJECTS":
      return {
        ...state,
        all_projects: {
          ...state.all_projects,
          ...payload,
        },
      };
    case "SET_ALL_COLLAB_PROJECTS":
      return {
        ...state,
        all_collab_projects: {
          ...state.all_collab_projects,
          ...payload,
        },
      };
    case "CREATE_PROJECT_SUCCESS":
      return {
        ...state,
        all_projects: {
          ...state.all_projects,
          data: {
            ...state.all_projects.data,
            docs: [...state.all_projects.data.docs, payload.data],
          },
        },
        project: {
          ...state.project,
          data: [...state.project.data, payload.data],
        },
      };
    case "UPDATE_PROJECT_SUCCESS":
      let tempProjectList3 = state.all_projects.data.docs;
      tempProjectList3 = tempProjectList3.map((item) => {
        if (item._id === payload.data._id) {
          return payload.data;
        }
        return item;
      });
      let tempProjectList1 = state.project.data;
      tempProjectList1 = tempProjectList1.map((item) => {
        if (item._id === payload.data._id) {
          return payload.data;
        }
        return item;
      });
      return {
        ...state,
        project: {
          ...state.project,
          data: tempProjectList1,
        },
        projectDetail: {
          ...state.projectDetail,
          data: payload.data,
        },
        all_projects: {
          ...state.all_projects,
          data: {
            ...state.all_projects.data,
            docs: tempProjectList3,
          },
        },
      };

    case "SET_ARCHIVED_PROJECTS_LIST":
      return {
        ...state,
        archivedProjectList: {
          ...state.archivedProjectList,
          ...payload,
        },
      };

    case "DELETE_PROJECT_SUCCESS":
      let tempProjectList4 = state.all_projects.data.docs;
      tempProjectList4 = tempProjectList4.filter(
        (item) => item._id !== payload.data._id
      );
      let tempProjectList5 = state.project.data;
      tempProjectList5 = tempProjectList5.filter(
        (item) => item._id !== payload.data._id
      );
      return {
        ...state,
        project: {
          ...state.project,
          data: tempProjectList5,
        },
        all_projects: {
          ...state.all_projects,
          data: {
            ...state.all_projects.data,
            docs: tempProjectList4,
          },
        },
      };
    case "CREATE_PROJECT_CATEGORY_SUCCESS":
      return {
        ...state,
        projectDetail: {
          ...state.projectDetail,
          data: payload.data,
        },
      };
    case "REMOVE_PROJECT_CATEGORY_SUCCESS":
      return {
        ...state,
        projectDetail: {
          ...state.projectDetail,
          data: payload.data,
        },
      };
    case "DUPLICATE_PROJECT_SUCCESS":
      return {
        ...state,
        projectDetail: {
          ...state.projectDetail,
          data: payload.data,
        },
        project: {
          ...state.project,
          data: [...state.project.data, payload.data],
        },
      };
    case "UPDATE_PROJECT_FAVORITE":
      const updatedProjects = state.all_projects.data?.docs.map((project) => {
        if (project._id === payload.projectId) {
          return { ...project, is_favourite: payload.favorite };
        }
        return project;
      });

      return {
        ...state,
        all_projects: {
          ...state.all_projects,
          data: {
            ...state.all_projects.data,
            docs: updatedProjects,
          },
        },
      };
    case "SET_ALL_PROJECTS_TASKS_SCREEN":
      return {
        ...state,
        all_tasks_screens: {
          data: removeDuplicate([
            ...state.all_tasks_screens.data,
            ...payload.data,
          ]),
        },
      };

    default:
      return state;
  }
};

export default projectReducer;
