import React from "react";
import StatusBadge from "./StatusBadge";

const OldAndNewValue = ({ type, value }) => {
  if (!value || value === "NONE") {
    return <p className="self-center text-neutral-600 min-w-fit">NONE</p>;
  }

  switch (type) {
    case "ProfileImage":
      return (
        <img
          className="!min-w-[36px] w-10 h-10 rounded-full group-hover:bg-indigo-500 bg-blend-overlay group-hover:bg-opacity-50 self-start"
          src={`${process.env.REACT_APP_BUCKET_NAME}${value}`}
          alt={value}
        />
      );
    case "Category":
      return (
        <span className="bg-AccentMediumLight h-fit w-fit px-3 py-1 rounded-lg text-AccentRegular font-medium">
          {Array.isArray(value) ? value.join(", ") : value}
        </span>
      );
    case "Status":
      return <StatusBadge status={value} />;
    case "Column":
      return <StatusBadge status={value} />;
    default:
      return <p className="self-start">{value}</p>;
  }
};

export default OldAndNewValue;
