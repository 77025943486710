import React from "react";
import Modal from "react-modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AddMediaToTaskAction } from "../../../../../../store/actions/tasksAction";
import { AddMediaToServiceAction } from "../../../../../../store/actions/serviceAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
    overflow: "hidden",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: 0,
    background: "none",
    borderRadius: "10px",
    overflow: "visible",
  },
};

const AddLinkModal = ({
  isModalOpen,
  setIsModalOpen,
  taskId,
  types,
  user_type,
  access_level,
  project,
}) => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [link, setLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const data = {
      id: taskId,
      name: text,
      link: link,
      is_external: true,
    };
    if (types === "task") {
      if (user_type) {
        dispatch(
          AddMediaToTaskAction(
            data,
            setIsLoading,
            project._id,
            setIsModalOpen,
            user_type
          )
        );
      } else {
        dispatch(
          AddMediaToTaskAction(data, setIsLoading, project._id, setIsModalOpen)
        );
      }
    }
    if (types === "service") {
      dispatch(AddMediaToServiceAction(data, setIsLoading, setIsModalOpen));
    }
  };

  return (
    // <div className="border-x-2 rounded-sm border-Neutral300 w-[36px] flex items-center justify-center">
    //   <button
    //     onClick={() => setIsModalOpen(true)}
    //     className="group text-[#757580] hover:text-[#292933] hover:bg-Neutral200 w-6 h-6 flex items-center justify-center rounded-sm	"
    //   >
    //     <svg
    //       width="20"
    //       height="20"
    //       viewBox="0 0 20 20"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M12.2727 6.21191H14.5455C15.0429 6.21191 15.5354 6.30989 15.995 6.50025C16.4546 6.69061 16.8722 6.96962 17.2239 7.32136C17.5756 7.6731 17.8546 8.09067 18.045 8.55023C18.2354 9.0098 18.3333 9.50236 18.3333 9.99979C18.3333 10.4972 18.2354 10.9898 18.045 11.4494C17.8546 11.9089 17.5756 12.3265 17.2239 12.6782C16.8722 13.03 16.4546 13.309 15.995 13.4993C15.5354 13.6897 15.0429 13.7877 14.5455 13.7877H12.2727M7.72727 13.7877H5.45454C4.95711 13.7877 4.46455 13.6897 4.00498 13.4993C3.54542 13.309 3.12785 13.03 2.77611 12.6782C2.06574 11.9679 1.66666 11.0044 1.66666 9.99979C1.66666 8.99518 2.06574 8.03172 2.77611 7.32136C3.48647 6.61099 4.44994 6.21191 5.45454 6.21191H7.72727"
    //         className="stroke-current"
    //         stroke="#757580"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //       <path
    //         d="M6.9696 10L13.0302 10"
    //         className="stroke-current"
    //         stroke="#757580"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //     </svg>
    //   </button>

    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      contentLabel="add link modal"
      style={customStyles}
    >
      <div>
        <div className="h-[56px] relative">
          <button
            className="absolute top-0 right-[8px]"
            onClick={() => setIsModalOpen(false)}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="31"
                height="31"
                rx="15.5"
                stroke="#DFDFE6"
              />
              <path
                d="M22 10L10 22"
                stroke="#EDEDF2"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 10L22 22"
                stroke="#EDEDF2"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <form
          className="bg-Neutral000 p-12 pt-8 flex flex-col gap-8 rounded-lg	"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-2">
            <p className="font-semibold	text-xs	text-Neutral700">ADD LINK</p>
            <hr className="bg-Neutral200" />
          </div>

          <div className="flex flex-col gap-6 w-[320px]">
            <div className="flex flex-col justify-between h-[72px]">
              <span className="font-bold	text-xs	text-Neutral900 pl-1 ">
                Text
              </span>
              <input
                className="border-Neutral300 border-[1.5px] p-3.5 h-[48px] font-medium	text-sm	"
                placeholder=""
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>

            <div className="flex flex-col justify-between h-[72px]">
              <span className="font-bold	text-xs	text-Neutral900 pl-1 ">
                Link
              </span>
              <input
                  type="url"
                  className="border-Neutral300 border-[1.5px] p-3.5 h-[48px] font-medium text-sm"
                  placeholder="Paste link here..."
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  required
                  pattern="^((https?:\/\/)?(www\.)?|www\.)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+([a-zA-Z0-9\/]*)$"
                  title="Please enter a valid URL. It can start with http://, https://, www., or just the domain name."
              />
            </div>
          </div>

          <button className="py-4 flex items-center justify-center bg-AccentRegular button-hover large-primary-btn text-xs text-center text-Neutral000  font-semibold rounded focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out">
            {isLoading ? (
              <span className="relative z-10">
                <svg
                  className="animate-spin m-auto h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            ) : (
              "Save"
            )}
          </button>
        </form>
      </div>
    </Modal>
    // </div>
  );
};

export default AddLinkModal;
