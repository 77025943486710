import { debounce } from "lodash";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloudLoaderIcon from "../../../../../assets/Images/Cloud-loader.svg";
import {
  CreateTaskActions,
  UpdateTaskActions,
  deleteTaskCustomFieldAction,
} from "../../../../../store/actions/tasksAction";
import CategoryTags from "../../components/CategoryTags";
import Comment from "../../components/Comment";
import DescriptionNew from "../../components/DescriptionNew";
import DueDateNew from "../../components/DueDateNew";
import Media from "../../components/Media";
import Subtasks from "../../components/Subtasks";
import StatusAndPriority from "./../../components/StatusAndPriority";
import { Menu, Transition } from "@headlessui/react";
import TrashIcon from "../../../../Dashboard-v2/icons/TrashIcon";
import ReactTooltip from "react-tooltip";

const CollabTaskMainContent = ({
  clientDetail,
  types,
  isFullSize = false,
  item,
  project,
  isNew = false,
  cardId,
  setTaskId,
  setNewTask,
  access_level,
}) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(null);
  const user = useSelector((state) => state?.collab_auth?.user);
  const [callApi, setCallApi] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [customFields, setCustomFields] = useState([]);
  const [isNewCustomField, setIsNewCustomField] = useState(false);
  const [customFieldsData, setCustomFieldsData] = useState({
    key: "",
    value: "",
  });

  useEffect(() => {
    if (item) {
      setEditable(item);
      setCustomFields(item?.custom_fields || []);
    }
  }, [item]);

  // Function to handle adding a new custom field
  const handleAddCustomField = () => {
    setIsNewCustomField(true);
  };

  // Function to handle changing custom field values
  const handleCustomFieldChange = (index, field, value) => {
    const newCustomFields = [...customFields];
    newCustomFields[index][field] = value;
    setCustomFields(newCustomFields);
  };

  const debouncedChangeHandler = useRef(
    debounce((editData) => {
      if (item?.title !== editData?.title) {
        if (types === "task") {
          dispatch(UpdateTaskActions(editData, setCallApi));
        }
      }
    }, 1000)
  ).current;

  const debouncedCreateHandler = useRef(
    debounce((editData) => {
      if (editData?.title?.length === 0) return;
      setUnsavedChanges(true);
      dispatch(
        CreateTaskActions(
          {
            title: editData?.title,
            client_id: clientDetail?._id,
            project: project?._id,
            type: "GENERAL",
            status: "to_do",
            tag: "not_started",
            column: project?.task_column[0]?.name,
            custom_fields: customFields,
          },
          setTaskId,
          setCallApi,
          setUnsavedChanges,
          setNewTask
        )
      );
    }, 1000)
  ).current;

  const handleChange = (e) => {
    setEditable({
      ...editable,
      [e.target.name]: e.target.value,
      user_type: "client_user",
    });
    if (isNew && !cardId) {
      debouncedCreateHandler({
        ...editable,
        [e.target.name]: e.target.value,
        user_type: "client_user",
      });
    } else {
      debouncedChangeHandler({
        ...editable,
        [e.target.name]: e.target.value,
        user_type: "client_user",
      });
    }
  };

  const handleAddCustomFieldClick = (e) => {
    if (customFieldsData.key && customFieldsData.value) {
      const newCustomFields = [
        ...(editable?.custom_fields || []),
        { key: customFieldsData.key, value: customFieldsData.value },
      ];

      setCustomFields(newCustomFields);
      setIsNewCustomField(false);
      setCustomFieldsData({ key: "", value: "" });

      const updatedEditable = {
        ...editable,
        custom_fields: newCustomFields,
        user_type: "client_user",
      };

      setEditable(updatedEditable);

      if (isNew && !cardId) {
        debouncedCreateHandler(updatedEditable);
      } else {
        debouncedChangeHandler(updatedEditable);
      }
    }
  };

  return (
    <div
      className={`relative bg-white rounded-lg rounded-tl-none text-left transform transition-all max-h-[85vh] ${
        isFullSize
          ? "h-full w-[1024px] mt-[114px] ml-[56px] mr-[0]"
          : "shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3) sm:w-full"
      }
      `}
    >
      <div className="flex flex-col lg:flex-row">
        <div
          className={`w-full py-4 px-5 pr-[${
            isFullSize ? "60px" : "20px"
          }] overflow-y-auto`}
        >
          <div className="relative flex items-start justify-between">
            <div className="flex flex-col w-full gap-2">
              <div
                className="w-full"
                data-for="collab-task-Tooltip"
                data-tip={item?.title}
              >
                {!editable && !isNew ? (
                  <p
                    className="text-Neutral900 text-[24px] font-semibold focus:outline-none truncate"
                    onClick={(e) => {
                      if (
                        clientDetail?.is_admin ||
                        clientDetail?.is_manager ||
                        user?._id === item?.created_by
                      ) {
                        setEditable(item);
                      }
                    }}
                  >
                    {item?.title}
                  </p>
                ) : (
                  <input
                    className="text-Neutral900 text-[24px] font-semibold focus:outline-none w-full truncate"
                    type="text"
                    name="title"
                    disabled={
                      callApi ? true : false || access_level !== "full_access"
                    }
                    value={editable?.title}
                    autoFocus={isNew ? true : false}
                    onChange={handleChange}
                  />
                )}

                <ReactTooltip
                  id="collab-task-Tooltip"
                  key={item?._id}
                  place="bottom"
                  effect="solid"
                  backgroundColor="#6D6DF2"
                />
              </div>

              <div className="flex justify-between">
                <CategoryTags
                  isNew={isNew}
                  unsavedChanges={unsavedChanges}
                  item={item}
                  clientDetail={clientDetail}
                  user={user}
                  types={types}
                  project={project}
                  //TODO: hardcode (delete after):
                  user_type="client_user"
                  access_level={access_level}
                />

                <div className="relative">
                  <div>
                    {unsavedChanges ? (
                      <span className="text-[#757580] text-xs font-semibold leading-4 ml-2.5 flex items-center">
                        <img
                          src={CloudLoaderIcon}
                          alt=""
                          className="mr-1 w-[35px]"
                        />
                        Saving...
                      </span>
                    ) : (
                      <StatusAndPriority
                        isNew={isNew}
                        item={item}
                        types={types}
                        isVertical={true}
                        user_type="client_user"
                        access_level={access_level}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex flex-col gap-3 relative mt-[32px] ${
              !isFullSize ? "overflow-y-auto scrollbar-hide h-[457px]" : ""
            }`}
          >
            <div className="flex gap-3">
              <DueDateNew
                isNew={isNew}
                item={item}
                user={user}
                types={types}
                clientDetail={clientDetail}
                user_type="client_user"
                access_level={access_level}
              />
            </div>
            <div className="flex w-full gap-3">
              <div className="flex flex-col gap-3 w-[60%]">
                <DescriptionNew
                  isNew={isNew}
                  item={item}
                  user={user}
                  types={types}
                  clientDetail={clientDetail}
                  //TODO: hardcode (delete after):
                  user_type="client_user"
                  access_level={access_level}
                />

                <Media
                  isNew={isNew}
                  item={item}
                  user={user}
                  types={types}
                  clientDetail={clientDetail}
                  project={project}
                  user_type="client_user"
                  access_level={access_level}
                />
              </div>

              <div className="flex flex-col gap-4 w-[40%]">
                <Subtasks
                  isNew={isNew}
                  item={item}
                  user={user}
                  types={types}
                  clientDetail={clientDetail}
                  user_type="client_user"
                  access_level={access_level}
                />

                <div className="flex flex-col gap-4">
                  {/* custom fields is an array of objects and each object has a key and value */}
                  <div className="flex flex-col gap-2">
                    {editable?.custom_fields?.map((field, index) => (
                      <div key={index} className="flex flex-col gap-1">
                        <label
                          htmlFor={field?.key}
                          className="flex items-center justify-between px-1 text-xs font-bold text-Neutral900"
                        >
                          <span>{field?.key}</span>
                          <Menu as="div" className="relative">
                            <Menu.Button className="text-Red500">
                              <TrashIcon />
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute z-50 origin-top-right bg-white rounded-md top-2 focus:outline-none right-5 w-max">
                                <div className="px-4 py-4">
                                  <Menu.Item>
                                    {({ close }) => (
                                      <div className="flex flex-col">
                                        <h5 className="pb-2 text-xs font-semibold border-b text-Neutral700 border-Neutral200">
                                          Are you sure?
                                        </h5>

                                        <button
                                          type="button"
                                          className="w-full px-4 py-2 mt-3 text-[10px] font-semibold rounded text-Neutral000 bg-Red400 hover:bg-Red600 tracking-wide"
                                          onClick={() => {
                                            dispatch(
                                              deleteTaskCustomFieldAction(
                                                item?._id, // Task Id
                                                field._id, // Custom field Id
                                                project._id, // Project Id
                                                true
                                              )
                                            );
                                          }}
                                        >
                                          Yes, Delete This Field
                                        </button>

                                        <button
                                          onClick={close}
                                          type="button"
                                          className="w-full px-4 py-2 mt-2 text-[10px] tracking-wide font-semibold rounded text-Neutral700 bg-Neutral300 hover:bg-Neutral400"
                                        >
                                          No, I'll Still keep This Field
                                        </button>
                                      </div>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </label>
                        <input
                          id={field?.key}
                          className="text-Neutral800 font-medium placeholder:text-Neutral500 py-2 px-3 placeholder:text-xs 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                          type="text"
                          placeholder={field?.key}
                          name={`custom_fields.${index}.value`}
                          onChange={(e) =>
                            handleCustomFieldChange(
                              index,
                              "value",
                              e.target.value
                            )
                          }
                          value={field?.value}
                        />
                      </div>
                    ))}
                  </div>

                  {customFields.length < 5 && (
                    <div className="flex items-center justify-between">
                      <button
                        type="button"
                        className="text-xs font-medium text-AccentRegular"
                        onClick={handleAddCustomField}
                      >
                        Add Custom Field
                      </button>
                      <span className="text-xs italic font-medium text-Neutral700">
                        Optional
                      </span>
                    </div>
                  )}

                  {isNewCustomField && (
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <span className="text-xs font-bold text-Neutral900">
                            Field Title
                          </span>
                        </div>
                        <div className="mt-2">
                          <input
                            placeholder="Eg. Postcode"
                            className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 transition"
                            type="text"
                            name="key"
                            onChange={(e) =>
                              setCustomFieldsData({
                                ...customFieldsData,
                                key: e.target.value,
                              })
                            }
                            value={customFieldsData.key}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <span className="text-xs font-bold text-Neutral900">
                            Field Info
                          </span>
                        </div>
                        <div className="mt-2">
                          <input
                            placeholder="Eg. 12345"
                            className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 transition"
                            type="text"
                            name="value"
                            onChange={(e) =>
                              setCustomFieldsData({
                                ...customFieldsData,
                                value: e.target.value,
                              })
                            }
                            value={customFieldsData.value}
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          className="relative block w-full py-3 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out rounded btn button-hover form-primary-btn text-Neutral100 bg-AccentRegular focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight"
                          disabled={
                            customFieldsData.key === "" ||
                            customFieldsData.value === ""
                          }
                          onClick={handleAddCustomFieldClick}
                        >
                          <span className="relative z-10">
                            Add Custom Field
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Comment
          isNew={isNew}
          element={item}
          task_id={item?._id}
          clientDetail={clientDetail}
          types={types}
          isFullSize={isFullSize}
          user_type="client_user"
          access_level={access_level}
        />
      </div>
    </div>
  );
};

export default CollabTaskMainContent;
