import React from 'react'

const StatusBadge = ({ status }) => {
  const getStatusStyles = (status) => {
    const statusStyle = {
      completed: "bg-green-200 text-green-800",
      Done: "bg-green-200 text-green-800",
      "Not started": "bg-neutral-200 text-neutral-800",
      "To Do": "bg-neutral-200 text-neutral-800",
      "In progress": "bg-blue-200 text-blue-800",
      Doing: "bg-blue-200 text-blue-800",
      default: "bg-AccentMediumLight text-AccentRegular",
    };
    return statusStyle[status] || statusStyle.default;
  };

  return (
    <span
      className={`${getStatusStyles(
        status
      )} h-fit w-fit px-3 py-1 rounded-lg font-medium`}
    >
      {status}
    </span>
  );
};

export default StatusBadge