import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { cn } from "../../utils";
import DeleteIcon from "../Icons/DeleteIcon";
import EditIcon from "../Icons/EditIcon";
import ThreeDotsIcon from "../Icons/ThreeDotsIcon";

function ActionMenuButton({
  className = "",
  onEdit,
  onDelete,
  clientDetail,
  memberDetail,
}) {
  return (
    <Menu
      as="div"
      className={cn("relative flex items-center text-left", className)}
    >
      <div className="flex items-center">
        <Menu.Button className="inline-flex justify-center w-full">
          <ThreeDotsIcon />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="edit-menu-servies edit-delete-menu absolute z-[999] top-0 right-0 w-[100px] rounded-lg bg-Neutral000 border-[0.5px] overflow-hidden border-Neutral200 divide-y divide-Neutral300 focus:outline-none ">
          <form method="POST" action="#">
            <Menu.Item>
              {({ active }) => (
                <div>
                  <div
                    className="w-[100px] text-left text-Neutral800 px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center cursor-pointer font-semibold hover:bg-Neutral200 hover:text-AccentRegular transition duration-300"
                    onClick={() => onEdit()}
                  >
                    <EditIcon className="mr-2" />
                    Edit
                  </div>
                  {clientDetail?.data?.is_admin === true &&
                  (memberDetail?.detail?.role !== "admin" ||
                    memberDetail?.user?.role !== "master_admin") ? (
                    <div
                      className="text-Neutral800 w-[100px] text-left px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200 hover:text-AccentRegular transition duration-300"
                      onClick={() => onDelete()}
                    >
                      <DeleteIcon className="mr-2 shrink-0" />
                      Remove
                    </div>
                  ) : null}
                </div>
              )}
            </Menu.Item>
          </form>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default ActionMenuButton;
