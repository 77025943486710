import axios from "axios";


async function getBase64Image (url) {
    const data = await axios(url);
    return data.data.data
  }

const syncImages = async () => {
    let element = document.getElementById("froala-editor-view");
   for (const ele of element.querySelectorAll('img')) {
    if(ele.currentSrc.startsWith('http')) {
        let isold;
        let fileName;
        if(ele?.currentSrc?.includes('clientmanager-documents-images')){
          fileName = ele.currentSrc?.split('clientmanager-documents-images.s3.amazonaws.com/')[1];
          isold = true;
        }
        if(ele?.currentSrc?.includes(process.env.REACT_APP_BUCKET_NAME)){
          fileName = ele.currentSrc.split(process.env.REACT_APP_BUCKET_NAME)[1];
          isold = false;
        }
        // const fileName = ele.currentSrc.split(process.env.REACT_APP_BUCKET_NAME)[1]
        ele.src = await getBase64Image(`/api/v1/proposal/get-proposals-documents?filename=${fileName}&isold=${isold}`)
      }
   }
    return true
  }

  export default syncImages