import { Popover, Transition } from "@headlessui/react";
import axios from "axios";
import copy from "copy-to-clipboard";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import Logo from "../../assets/Images/Logo.svg";
import { PaypalIcon } from "../../components/CreateInvoiceForm/PaypalIcon";
import { StripeIcon } from "../../components/CreateInvoiceForm/StripeIcon";
import EditIcon from "../../components/Icons/EditIcon";
import EmailIcon from "../../components/Icons/EmailIcon";
import LoadingIcon from "../../components/Icons/LoadingIcon";
import SendIcon from "../../components/Icons/SendIcon";
import FollowUpEmailModal from "../../components/Modals/FollowUpEmailModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import CopyLinkModalForInvoice from "../../components/Public Invoice Builder/CopyLinkModal";
import TagList from "../../components/Templates/TemplateInfo/components/TagList";
import FeaturesModal from "../../components/White-Label/FeaturesModal";
import WhiteLabelButton from "../../components/White-Label/WhiteLabelButton";
import useBaseDomainURL from "../../hooks/useBaseDomainURL";
import useGetBrandIdentityForPublicRoute from "../../hooks/useGetBrandIdentityForPublicRoute";
import useLogo from "../../hooks/useLogo";
import { GetClientDetailAction } from "../../store/actions/clientAction";
import {
  PaypalOrderCreate,
  getInvoiceById,
  stripeOrderCreate,
  updateInvoice,
} from "../../store/actions/invoicesAction";
import { cn } from "../../utils";
import { getTagColor } from "../../utils/getTagColor";
import { toast } from "react-toastify";

function Invoices() {
  const { clientId, invoiceId } = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const baseDomainUrl = useBaseDomainURL();
  const { Logo: logoSrc, isCustom } = useLogo();

  const clientDetail = useSelector((state) => state.client.clientDetail);
  const loggedInUser = useSelector((state) => state.auth.user);
  const invoice = useSelector((state) => state.invoice.invoice);
  const { brandIdentity } = useGetBrandIdentityForPublicRoute(clientId);

  const [showCategoryTagsMenu, setShowCategoryTagsMenu] = useState(false);
  const [showInvoiceTitleMenu, setShowInvoiceTitleMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userDateFormat = loggedInUser?.date_format || "DD/MM/YYYY";
  const [linkIsOpen, setLinkIsOpen] = useState(false);
  const [copyStatus, setCopyStatus] = useState("Copy Link");
  const [copyPaymentLink, setCopyPaymentLink] = useState({
    paypal: "Copy",
    stripe: "Copy",
  });
  const [newTitle, setNewTitle] = useState("");
  const [loadingPaymentLink, setLoadingPaymentLink] = useState();
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [
    isWhiteLabelFeaturesModalOpen,
    setIsWhiteLabelFeaturesModalOpen,
  ] = useState(false);
  const [showCopyButton, setShowCopyButton] = useState(false);

  const modalColor = "Pink";
  const companyName = loggedInUser?.brandIdentity?.companyName;
  const isWhiteLabelUser = loggedInUser?.brandIdentity?.status === "active";

  const options = [
    { value: "Web Design", label: "Web Design" },
    { value: "App Development", label: "App Development" },
    { value: "SEO", label: "SEO" },
    { value: "Content Writing", label: "Content Writing" },
  ];

  const optionsWithColors = options.map((option, i) => {
    const { bgColor: optionColor, text: optionText } = getTagColor(
      modalColor,
      i,
      true
    );

    const { bgColor: labelColor, text: labelText } = getTagColor(
      modalColor,
      i,
      true
    );

    return {
      ...option,
      color: optionColor,
      text: optionText,
      labelColor,
      labelText,
    };
  });

  useEffect(() => {
    dispatch(getInvoiceById(clientId, invoiceId));
  }, [clientId, dispatch, invoiceId]);

  useEffect(() => {
    if (state) {
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { isLoading: false, isUpdating: false, data: state },
      });
    } else {
      if (clientId !== clientDetail.data._id) {
        dispatch(GetClientDetailAction(clientId, navigate));
      }
    }
  }, [clientDetail.data._id, clientId, dispatch, navigate, state]);

  const subtotal = invoice?.services?.reduce((acc, service) => {
    if (service?.serviceType === "Fixed") {
      return acc + Number(service?.servicePrice);
    } else {
      return acc + Number(service?.quantity) * Number(service?.servicePrice);
    }
  }, 0);

  function roundToTwoDecimals(value) {
    if (isNaN(value)) return 0;
    return Math.round(value * 100) / 100;
  }

  function formatDate(dateString) {
    if (!dateString) return "";
    return moment(dateString).format(userDateFormat);
  }

  const getDiscount = (subtotal, discount) => {
    const subtotalNum = Number(subtotal) || 0;
    const discountNum = Number(discount);
    if (isNaN(discountNum) || discountNum === 0 || isNaN(subtotalNum)) return 0;
    return roundToTwoDecimals((subtotalNum * discountNum) / 100);
  };

  const getVat = (subtotal, vatPercentage, discount) => {
    const subtotalNum = Number(subtotal) || 0;
    const vatPercentageNum = Number(vatPercentage);
    if (isNaN(vatPercentageNum) || vatPercentageNum === 0 || isNaN(subtotalNum))
      return 0;
    const discountedAmount = subtotalNum - getDiscount(subtotalNum, discount);
    return roundToTwoDecimals((discountedAmount * vatPercentageNum) / 100);
  };

  const getTotalDue = (subtotal, discount, vatPercentage, taxes, isVAT) => {
    const subTotalNum = Number(subtotal) || 0;
    const discountNum = Number(discount) || 0;
    const vatPercentageNum = Number(vatPercentage) || 0;

    const taxesValid = Array.isArray(taxes) && taxes.length > 0;

    if (isVAT) {
      return roundToTwoDecimals(
        subTotalNum -
          getDiscount(subTotalNum, discountNum) +
          getVat(subTotalNum, vatPercentageNum, discountNum)
      );
    } else if (taxesValid) {
      const totalTaxes = taxes.reduce((total, tax) => {
        const taxValueNum = Number(tax.taxValue) || 0;
        return total + getVat(subTotalNum, taxValueNum, discountNum);
      }, 0);
      return roundToTwoDecimals(
        subTotalNum - getDiscount(subTotalNum, discountNum) + totalTaxes
      );
    } else {
      return roundToTwoDecimals(
        subTotalNum - getDiscount(subTotalNum, discountNum)
      );
    }
  };

  const handleDownloadInvoice = async () => {
    setIsLoading(true);
    const filename = `${invoice?.invoiceName}-${invoice?.invoiceNumber}-${invoice?.clientName}-${invoice?.invoiceDate}.pdf`;

    const contentWidthInInches = 620 / 96;
    const pageWidthInInches = 8.5;
    const sideMargin = (pageWidthInInches - contentWidthInInches) / 3;

    let elem = document.getElementById("invoice");
    var newElem = elem.cloneNode(true);

    const height = Math.ceil(elem.scrollHeight / 1000);

    newElem.setAttribute("id", "download-invoice");
    newElem.style.height = `${height * 1000}px`;

    // Select all #section but not select the last section.
    const sections = newElem.querySelectorAll("#section");
    const allButLast = Array.from(sections).slice(0, -1);
    allButLast.forEach((el) => {
      el.style.marginTop = "50px";
    });

    if (invoice?.paymentLink && invoice?.paymentMethod !== "Bank") {
      if (newElem.querySelectorAll("#paypalicon")?.length) {
        newElem.querySelectorAll("#paypalicon")[0].style.paddingTop = "1.25rem";
      }
    }

    if (newElem.querySelector("#cm_logo")) {
      newElem.querySelector("#cm_logo").style.marginTop = "10px";
    }
    // newElem.querySelector("#logo_div").style.marginRight = "50%";

    if (!loggedInUser?.white_label_payment) {
      newElem.querySelector("#white-label-btn").style.display = "none";
    }

    async function getBase64Image(url) {
      const data = await axios(url);
      return data.data.data;
    }

    let element = newElem.querySelector("#cm_logo");
    if (element && element.currentSrc.startsWith("http")) {
      const fileName = element.currentSrc.split(
        process.env.REACT_APP_BUCKET_NAME
      )[1];
      newElem.querySelector("#cm_logo").src = await getBase64Image(
        `/api/v1/invoice/get-invoice-base64?filename=${fileName}`
      );
    }

    html2pdf()
      .from(newElem)
      .set({
        margin: [0.5, sideMargin, 0.5, sideMargin],
        filename: filename,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 4 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      })
      .toPdf()
      .get("pdf")
      .save(filename)
      .catch((err) => console.log("Error:", err));
    setIsLoading(false);
  };

  const saveInvoiceTitle = () => {
    const newInvoice = {
      ...invoice,
      invoiceName: newTitle,
      client: clientId,
    };

    dispatch(updateInvoice(invoiceId, newInvoice, navigate));
    dispatch({
      type: "SET_INVOICE",
      payload: { data: { ...invoice, invoiceName: newTitle } },
    });
    setShowInvoiceTitleMenu(false);
  };

  if (isLoading) return <div>Loading...</div>;

  if (!invoice) {
    return null;
  }

  const handlePaypal = async () => {
    if (invoice?.paymentLink && invoice.paymentMethod === "Paypal") {
      copy(invoice?.paymentLink);
      setCopyPaymentLink({ paypal: "Copied" });
      setTimeout(() => {
        setCopyPaymentLink({ paypal: "Copy" });
        setShowCopyButton(true);
      }, 1000);
    } else {
      dispatch(PaypalOrderCreate(invoiceId, setLoadingPaymentLink));
    }
  };

  const handleStipe = async () => {
    if (invoice?.paymentLink && invoice.paymentMethod === "Stripe") {
      copy(invoice?.paymentLink);
      setCopyPaymentLink({ stripe: "Copied" });
      setTimeout(() => {
        setCopyPaymentLink({ stripe: "Copy" });
        setShowCopyButton(true);
      }, 1000);
    } else {
      dispatch(stripeOrderCreate(invoiceId, setLoadingPaymentLink));
    }
  };

  const canProceedWithDownload = () => {
    if (invoice) {
      const passedBankSecurityCheck =
        (invoice?.paymentMethod === "Bank" && invoice?.bankDetails !== "") ||
        ["Stripe", "Paypal"].includes(invoice?.paymentMethod) ||
        (invoice?.paymentMethod === "Custom" && invoice?.paymentLink !== "");
      const passedServiceStepSecurity = invoice?.services?.length > 0;
      return passedBankSecurityCheck && passedServiceStepSecurity;
    }
    return false;
  };

  return (
    <>
      <AdminNavbar
        title="Create Invoice"
        subtitle="Build and send to your potential client to complete online."
      />

      <div
        className={`flex gap-4 items-start mt-6 mb-32 box-shadow-hidden md:px-6 2xl:px-12`}
      >
        <div className="flex flex-col w-full mb-5">
          <div className="flex flex-col items-start justify-between p-6 md:flex-row md:p-0">
            <div className="flex flex-col items-center justify-center w-full gap-3 pb-6 mr-3 md:pb-0 md:w-1/3">
              <div className="flex flex-col flex-wrap w-full gap-6">
                <div className="flex justify-between w-full gap-2 md:flex-col">
                  <h5 className="text-sm font-semibold text-Neutral500">
                    Client
                  </h5>

                  {/* name */}
                  <div className="flex ">
                    <div className="flex items-center justify-start gap-1 pr-3 font-medium bg-gray-100 rounded-full z-1">
                      {clientDetail?.data?.profile_path ? (
                        <img
                          className="w-6 h-6 rounded-full z-99"
                          src={clientDetail?.data?.profile_path}
                          alt=""
                        />
                      ) : (
                        <div className="flex items-center justify-center w-6 h-6 bg-gray-300 rounded-full z-99">
                          {clientDetail?.data?.client_name?.charAt(0)}
                        </div>
                      )}
                      <div className="px-3 text-xs font-semibold text-Neutral800">
                        {invoice?.clientName || "Client Name"}
                      </div>
                    </div>
                  </div>
                </div>
                {/* name */}

                {/* title */}
                <div className="flex justify-between w-full gap-2 md:flex-col">
                  <div className="flex items-center justify-between">
                    <h5 className="text-sm font-semibold text-Neutral500">
                      Invoice Title
                    </h5>

                    <div className="relative flex items-center gap-2">
                      {showInvoiceTitleMenu && (
                        <div className="absolute w-48 bg-white rounded-md shadow-lg top-2 right-1 ring-1 ring-black ring-opacity-5">
                          <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            <input
                              type="text"
                              className="w-full px-4 py-2"
                              name="newTitle"
                              placeholder="New Title"
                              value={newTitle}
                              onChange={(e) => {
                                setNewTitle(e.target.value);
                              }}
                            />
                            <button
                              className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                              type="button"
                              onClick={() => {
                                saveInvoiceTitle();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="px-2 text-xs font-semibold md:px-0 text-Neutral800">
                    {invoice.invoiceName || "Invoice Title"}
                  </div>
                </div>
                {/* title */}

                {/* category tags */}
                <div className="flex justify-between w-full gap-2 md:flex-col">
                  <div className="flex items-center justify-between">
                    <h5 className="text-sm font-semibold text-Neutral500">
                      Category Tags
                    </h5>

                    <div className="relative flex items-center gap-2">
                      {showCategoryTagsMenu && (
                        <div className="absolute w-48 bg-white rounded-md shadow-lg top-2 right-1 ring-1 ring-black ring-opacity-5">
                          <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            {optionsWithColors
                              ?.filter(
                                (option) =>
                                  !invoice?.categoryTags?.find(
                                    (tag) => tag?.name === option?.value
                                  )
                              )
                              ?.map((tag, i) => {
                                const { bgColor, text } = getTagColor(
                                  modalColor,
                                  i
                                );
                                return (
                                  <button
                                    className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                    role="menuitem"
                                    type="button"
                                    onClick={() => {
                                      const newInvoice = {
                                        ...invoice,
                                        categoryTags: [
                                          ...invoice.categoryTags,
                                          {
                                            name: tag?.value,
                                            color: {
                                              color: tag?.color,
                                              text: tag?.text,
                                              labelColor: tag?.labelColor,
                                              labelText: tag?.labelText,
                                            },
                                          },
                                        ],
                                        client: clientId,
                                      };

                                      dispatch(
                                        updateInvoice(
                                          invoiceId,
                                          newInvoice,
                                          navigate
                                        )
                                      );
                                      dispatch({
                                        type: "SET_INVOICE",
                                        payload: {
                                          data: {
                                            ...invoice,
                                            categoryTags: [
                                              ...invoice.categoryTags,
                                              {
                                                name: tag?.value,
                                                color: {
                                                  color: tag?.color,
                                                  text: tag?.text,
                                                  labelColor: tag?.labelColor,
                                                  labelText: tag?.labelText,
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      });

                                      setShowCategoryTagsMenu(false);
                                    }}
                                  >
                                    <div
                                      className={`bg-${bgColor} py-1 px-2 text-${text} rounded-lg text-[10px] uppercase font-medium tracking-[0.04em] mb-2`}
                                      key={i}
                                    >
                                      {tag?.value}
                                    </div>
                                  </button>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex px-2 py-1 text-xs font-semibold rounded text-Neutral800">
                    <TagList
                      color={"Pink"}
                      tags={invoice?.categoryTags?.map((tag) => tag?.name)}
                    />
                  </div>
                </div>
                {/* category tags */}

                <hr />

                <div className="flex flex-wrap w-full gap-2">
                  {canProceedWithDownload() && (
                    <div className="flex flex-wrap w-full gap-2">
                      <button
                        className="px-[19px] py-3 h-[48px] rounded flex justify-center items-center grow text-xs text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out bg-AccentRegular hover:bg-AccentMediumDark text-white"
                        type="button"
                        onClick={() => {
                          const link = `${baseDomainUrl}/public-invoice/${clientId}/invoice/${invoiceId}?user=${loggedInUser._id}`;
                          copy(link);
                          setLinkIsOpen(true);
                          setCopyStatus("Copied");
                          setTimeout(() => {
                            setCopyStatus("Copy Link");
                          }, 1000);
                        }}
                      >
                        Copy Invoice Link
                      </button>

                      {invoice.paymentMethod !== "Bank" &&
                        !showCopyButton &&
                        invoice?.paymentMethod !== "Custom" && (
                          <button
                            type="button"
                            className="px-6 py-3 h-[48px] rounded flex justify-center gap-2 items-center text-xs text-center w-full font-bold focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 bg-AccentRegular hover:bg-AccentMediumDark text-white"
                            disabled={loadingPaymentLink}
                            onClick={() => {
                              if (invoice.paymentMethod === "Stripe") {
                                handleStipe();
                              } else if (invoice.paymentMethod === "Paypal") {
                                handlePaypal();
                              } else {
                                console.log("Unsupported payment method");
                              }
                            }}
                          >
                            {loadingPaymentLink ? (
                              <LoadingIcon />
                            ) : (
                              <SendIcon width={18} color="#fff" />
                            )}
                            Generate {invoice?.paymentMethod} Link
                          </button>
                        )}

                      {invoice.paymentMethod !== "Bank" && showCopyButton && (
                        <button
                          type="button"
                          className="px-6 py-3 h-[48px] rounded flex justify-center gap-2 items-center text-xs text-center w-full font-bold focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 bg-AccentRegular hover:bg-AccentMediumDark text-white"
                          disabled={loadingPaymentLink}
                          onClick={() => {
                            copy(invoice?.paymentLink);
                            toast.success(
                              `Copied ${invoice?.paymentMethod} Link`
                            );
                          }}
                        >
                          {loadingPaymentLink && <LoadingIcon />}
                          Copy {invoice?.paymentMethod} Link
                        </button>
                      )}

                      {/* <Popover className="relative w-full">
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className="px-6 py-3 h-[48px] rounded flex justify-center gap-2 items-center text-xs text-center w-full font-bold focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 bg-AccentRegular hover:bg-AccentMediumDark text-white"
                              disabled={loadingPaymentLink}
                              type="button"
                            >
                              {loadingPaymentLink ? (
                                <LoadingIcon />
                              ) : (
                                <SendIcon width={18} color="#fff" />
                              )}
                              Generate {invoice?.paymentMethod} Link
                            </Popover.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute z-10 w-full mt-3 transform -translate-x-1/2 left-1/2 ">
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                  <div className="relative p-3 bg-white">
                                    <button
                                      disabled={loadingPaymentLink}
                                      type="button"
                                      onClick={() => handleStipe()}
                                      className="5xl:w-[120px] text-left px-2 py-2 text-sm whitespace-nowrap flex items-center justify-center font-medium text-Neutral800 hover:bg-Neutral100 "
                                    >
                                      <span>
                                        {invoice?.paymentLink &&
                                        invoice?.paymentMethod === "Stripe"
                                          ? copyPaymentLink.stripe
                                          : "Add "}{" "}
                                        Stripe Payment Link
                                      </span>
                                    </button>
                                    <button
                                      disabled={loadingPaymentLink}
                                      className="5xl:w-[120px] text-left px-2 py-2 text-sm whitespace-nowrap flex items-center justify-center font-medium text-Neutral800 hover:bg-Neutral100"
                                      type="button"
                                      onClick={() => handlePaypal()}
                                    >
                                      {!invoice?.paymentLink && (
                                        <>
                                          {loadingPaymentLink && (
                                            <LoadingIcon />
                                          )}
                                        </>
                                      )}
                                      <span>
                                        {invoice?.paymentLink &&
                                        invoice?.paymentMethod === "Paypal"
                                          ? copyPaymentLink.paypal
                                          : "Add "}{" "}
                                        PayPal Payment Link
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover> */}
                    </div>
                  )}

                  {canProceedWithDownload() ? (
                    <div className="flex flex-wrap w-full gap-2">
                      <button
                        type="button"
                        className="px-6	py-3 h-[48px] bg-Neutral200 rounded flex justify-center items-center grow text-AccentRegular text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out hover:bg-AccentMediumLight"
                        data-tip="Edit Invoice"
                        data-for="button-tooltip"
                        onClick={() => {
                          navigate(
                            `/admin/create-invoice/${clientId}/${invoiceId}`
                          );
                        }}
                      >
                        <EditIcon width={25} className="text-AccentRegular" />
                      </button>

                      <button
                        type="button"
                        className="px-6	py-3 h-[48px] bg-Neutral200 rounded flex justify-center items-center grow text-AccentRegular text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out hover:bg-AccentMediumLight"
                        data-tip="Download Invoice"
                        data-for="button-tooltip"
                        onClick={handleDownloadInvoice}
                      >
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.25 15.168V18.7235C21.25 19.195 21.0627 19.6472 20.7293 19.9806C20.3959 20.314 19.9437 20.5013 19.4722 20.5013H7.02778C6.55628 20.5013 6.1041 20.314 5.7707 19.9806C5.4373 19.6472 5.25 19.195 5.25 18.7235V15.168"
                            stroke="var(--accent-color)" //#6D6DF2
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.80518 10.7217L13.2496 15.1661L17.6941 10.7217"
                            stroke="var(--accent-color)" //#6D6DF2
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.25 15.1662V4.49951"
                            stroke="var(--accent-color)" //#6D6DF2
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      <button
                        className="px-6	py-3 h-[48px] bg-Neutral200 rounded flex justify-center items-center grow text-AccentRegular text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out hover:bg-AccentMediumLight"
                        data-tip="Email Invoice"
                        data-for="button-tooltip"
                        onClick={() => setShowEmailModal(true)}
                      >
                        <EmailIcon width={25} className="text-AccentRegular" />
                      </button>

                      <ReactTooltip id="button-tooltip" effect="solid" />
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="flex items-center justify-center px-6 py-4 text-xs font-bold text-center rounded bg-Neutral200 grow text-AccentRegular focus:outline hover:bg-AccentMediumLight"
                      data-tip="Edit Invoice"
                      data-for="button-tooltip"
                      onClick={() => {
                        navigate(
                          `/admin/create-invoice/${clientId}/${invoiceId}`
                        );
                      }}
                    >
                      Edit Invoice
                    </button>
                  )}
                </div>
              </div>

              {linkIsOpen && (
                <CopyLinkModalForInvoice
                  setCopyStatus={setCopyStatus}
                  closeModal={() => setLinkIsOpen(false)}
                  linkIsOpen={linkIsOpen}
                  url={`${baseDomainUrl}/public-invoice/${clientId}/invoice/${invoiceId}`}
                  setLinkIsOpen={setLinkIsOpen}
                  handleEmailModal={() => {
                    setShowEmailModal(true);
                    setLinkIsOpen(false);
                  }}
                />
              )}
            </div>

            {/* right */}
            {/* A4 */}
            <div
              className={cn(
                "relative flex flex-col gap-10 flex-grow w-full rounded max-w-full md:max-w-[620px] bg-white border border-Neutral300 shadow-2xl p-8 py-5 h-[750px] overflow-y-auto shadow-Neutral400"
              )}
              id="invoice"
            >
              {isWhiteLabelUser && isCustom && logoSrc && (
                <div className="-mb-5">
                  <img
                    src={logoSrc}
                    alt={`${companyName} Logo`}
                    id="cm_logo"
                    className="size-7"
                  />
                </div>
              )}

              {/* SECTION 1 */}
              <div className="flex flex-row items-start justify-between">
                <h1 className="flex w-full text-2xl font-bold text-Neutral900">
                  <span>INVOICE #{invoice?.invoiceNumber}</span>
                </h1>

                <div className="flex flex-row items-start justify-between">
                  <div className="flex flex-col float-right gap-1 text-left">
                    {invoice?.logo && (
                      <img
                        src={invoice?.logo}
                        alt=""
                        className="self-end w-24"
                      />
                    )}

                    <h5 className="text-sm font-semibold uppercase text-Neutral800">
                      {invoice?.fullName}
                    </h5>
                    <div className="text-sm text-zinc-800">
                      {invoice?.companyName}
                    </div>
                    <div className="text-sm text-Neutral800">
                      {invoice?.email}
                    </div>
                    <div className="text-sm text-Neutral800">
                      {invoice.phoneNumber}
                    </div>
                    <div className="text-sm text-Neutral800">
                      {invoice.address}
                    </div>
                    <div className="text-sm text-Neutral800">{invoice.VAT}</div>
                  </div>
                </div>
              </div>

              {/* SECTION 2 */}
              <div
                className="flex flex-row items-center justify-between"
                id="section"
              >
                <div className="flex flex-col w-full gap-1">
                  <h5 className="text-sm font-semibold uppercase text-Neutral800">
                    Bill To
                  </h5>
                  <div className="text-sm font-semibold text-Neutral800">
                    {invoice?.clientCompanyName}
                  </div>
                  <div className="text-sm font-semibold text-Neutral800">
                    {invoice?.clientName}
                  </div>
                  <div className="text-sm text-Neutral800">
                    {invoice?.clientAddress}
                  </div>
                  <div className="text-sm text-Neutral800">
                    {invoice?.clientEmail}
                  </div>
                  <div className="text-sm text-Neutral800">
                    {invoice?.clientPhoneNumber}
                  </div>
                  <div className="text-sm text-Neutral800">
                    {invoice?.clientVAT}
                  </div>
                </div>
                <div className="flex flex-col w-full gap-1 text-right">
                  <h5 className="text-sm font-semibold uppercase text-Neutral800">
                    Invoice Details
                  </h5>
                  <div className="text-sm text-Neutral500">Invoice Date</div>
                  <div className="text-sm font-semibold text-Neutral800">
                    {formatDate(invoice?.invoiceDate)}
                  </div>
                  <div className="text-sm text-Neutral500">Due Date</div>
                  <div className="text-sm font-semibold text-Neutral800">
                    {formatDate(invoice?.dueDate)}
                  </div>
                </div>
              </div>

              {/* SECTION 3 */}
              <div className="flex flex-col gap-3">
                <div className="relative">
                  <table
                    className="w-full text-sm text-left text-gray-500"
                    id="section"
                  >
                    <thead className="text-xs font-normal text-gray-400">
                      <tr>
                        <th scope="col" className="w-2/5 py-2">
                          Description
                        </th>
                        {invoice?.services?.some(
                          (service) => service?.serviceType === "Hourly"
                        ) && (
                          <th scope="col" className="w-1/5 py-2 text-left">
                            Hours
                          </th>
                        )}

                        <th scope="col" className="w-1/5 py-2 text-left">
                          {invoice?.services?.some(
                            (service) => service?.serviceType === "Hourly"
                          ) && <>Price/HR</>}
                        </th>

                        <th scope="col" className="w-1/5 py-2 text-left">
                          Subtotal
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice?.services?.map((service, i) => (
                        <tr className="bg-white border-b" key={i}>
                          <td
                            scope="row"
                            className="w-3/5 py-2 pr-4 font-medium text-gray-900 break-all"
                          >
                            {service?.serviceName}
                          </td>
                          <td className="py-2 text-left">
                            {service?.serviceType !== "Fixed" && (
                              <>{service?.quantity}</>
                            )}
                          </td>
                          <td className="py-2 text-left">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: invoice?.currency || "USD",
                            }).format(service?.servicePrice)}
                          </td>
                          <td className="py-2 text-right">
                            {service.serviceType === "Fixed" ? (
                              <>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: invoice?.currency || "USD",
                                }).format(service?.servicePrice)}
                              </>
                            ) : (
                              <>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: invoice?.currency || "USD",
                                }).format(
                                  service?.quantity * service?.servicePrice
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                      <tr className="bg-white">
                        <th
                          scope="row"
                          className="py-2 font-medium text-gray-900 whitespace-nowrap"
                        ></th>
                        <td className="py-2 text-left"></td>
                        <td className="py-2 text-left">Subtotal</td>
                        <td className="py-2 text-right">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: invoice?.currency || "USD",
                          }).format(subtotal)}
                        </td>
                      </tr>
                      {invoice?.discount > 0 && (
                        <tr className="bg-white">
                          <th
                            scope="row"
                            className="py-2 font-medium text-gray-900 whitespace-nowrap"
                          ></th>
                          <td className="py-2 text-left"></td>
                          <td className="py-2 text-left">
                            Discount ({invoice?.discount}%)
                          </td>
                          <td className="py-2 text-right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: invoice?.currency || "USD",
                            }).format(getDiscount(subtotal, invoice?.discount))}
                          </td>
                        </tr>
                      )}
                      {(invoice?.is_VAT && (
                        <tr className="bg-white">
                          <th
                            scope="row"
                            className="py-2 font-medium text-gray-900 whitespace-nowrap"
                          ></th>
                          <td className="py-2 text-left"></td>
                          <td className="py-2 text-left">
                            VAT ({invoice?.vatPercentage}%)
                          </td>
                          <td className="py-2 text-right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: invoice?.currency || "USD",
                            }).format(
                              getVat(
                                subtotal,
                                invoice?.vatPercentage,
                                invoice?.discount
                              )
                            )}
                          </td>
                        </tr>
                      )) ||
                        invoice.taxes?.map((tax, i) => (
                          <tr className="bg-white">
                            <th
                              scope="row"
                              className="py-2 font-medium text-gray-900 whitespace-nowrap"
                            ></th>
                            <td className="py-2 text-left"></td>
                            <td className="py-2 text-left">
                              {tax.taxName} ({tax?.taxValue}%)
                            </td>
                            <td className="py-2 text-right">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: invoice?.currency || "USD",
                              }).format(
                                getVat(
                                  subtotal,
                                  tax?.taxValue,
                                  invoice?.discount
                                )
                              )}
                            </td>
                          </tr>
                        ))}
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="py-2 font-medium text-gray-900 whitespace-nowrap"
                        ></th>
                        <td className="py-2 text-left"></td>
                        <td className="py-2 font-semibold text-left uppercase text-zinc-800">
                          Total Due
                        </td>
                        <td className="py-2 font-semibold text-right uppercase text-zinc-800">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: invoice?.currency || "USD",
                          }).format(
                            getTotalDue(
                              subtotal,
                              invoice?.discount,
                              invoice?.vatPercentage,
                              invoice?.taxes,
                              invoice?.is_VAT
                            )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* SECTION 3 */}

                {/* SECTION 4 */}
                <div id="section">
                  {invoice.paymentLink && invoice.paymentMethod === "Paypal" && (
                    <Link
                      to={invoice.paymentLink}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center justify-end gap-2 cursor-pointer"
                    >
                      <span className="text-sm font-semibold underline text-zinc-800 ">
                        Click here to pay with
                      </span>
                      <span
                        className="text-sm font-semibold text-zinc-800"
                        id="paypalicon"
                      >
                        <PaypalIcon />
                      </span>
                    </Link>
                  )}
                  {invoice.paymentLink && invoice.paymentMethod === "Stripe" && (
                    <Link
                      to={invoice.paymentLink}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center justify-end gap-2 cursor-pointer"
                    >
                      <span className="text-sm font-semibold underline text-zinc-800 ">
                        Click here to pay with
                      </span>
                      <span
                        className="text-sm font-semibold text-zinc-800"
                        id="paypalicon"
                      >
                        <StripeIcon />
                      </span>
                    </Link>
                  )}
                  {invoice?.paymentLink && invoice?.paymentMethod === "Custom" && (
                    <Link
                      to={invoice?.paymentLink}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center justify-end gap-2 cursor-pointer"
                    >
                      <span className="text-sm font-semibold underline text-zinc-800 ">
                        Click here to make payment
                      </span>
                    </Link>
                  )}
                  {invoice.paymentMethod === "Bank" && invoice?.bankDetails && (
                    <div className="flex flex-col w-full gap-2 p-4 mt-3 border border-Neutral200 ">
                      <h5 className="text-sm font-semibold uppercase text-Neutral800">
                        Bank Details
                      </h5>
                      <div className="text-sm whitespace-pre-wrap text-Neutral700">
                        {invoice.bankDetails}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* SECTION 4 */}
              <div className="bg-Neutral000 w-full max-w-[788px] mx-auto mt-auto flex items-center">
                {!isWhiteLabelUser && (
                  <div className="flex items-center justify-center flex-grow">
                    <a
                      href="https://www.clientmanager.io/"
                      target="_blank"
                      rel="noreferrer"
                      id="logo_div"
                      className="text-Neutral700 text-[10px] font-normal leading-[7px] flex items-center"
                    >
                      <img
                        src={Logo}
                        alt="ClientManager Logo"
                        id="cm_logo"
                        className="mr-[6px] size-5"
                      />
                      Made with
                      <span className="text-gray-500 font-bold ml-[2px]">
                        ClientManager
                      </span>
                    </a>
                  </div>
                )}

                {/* {(() => {
                  if (isCustom && companyName) {
                    // Case 1: Custom logo and company name
                    return (
                      <div className="flex items-center justify-center flex-grow">
                        <a
                          href="#"
                          target="_blank"
                          rel="noreferrer"
                          id="logo_div"
                          className="text-Neutral700 text-[10px] font-normal leading-[7px] flex items-center"
                        >
                          <img
                            src={logoSrc}
                            alt={`${companyName} Logo`}
                            id="cm_logo"
                            className="mr-[6px] h-8 w-8"
                          />
                          Made with
                          <span className="text-gray-500 font-bold ml-[2px]">
                            {companyName}
                          </span>
                        </a>
                      </div>
                    );
                  } else if (isCustom && !companyName) {
                    // Case 2: Custom logo only
                    return (
                      <div className="flex items-center justify-center flex-grow">
                        <img
                          src={logoSrc}
                          alt="Company Logo"
                          id="cm_logo"
                          className="w-8 h-8"
                        />
                      </div>
                    );
                  } else if (!isCustom && !isWhiteLabelUser) {
                    // Case 3: Default branding for normal user
                    return (
                      <div className="flex items-center justify-center flex-grow">
                        <a
                          href="https://www.clientmanager.io/"
                          target="_blank"
                          rel="noreferrer"
                          id="logo_div"
                          className="text-Neutral700 text-[10px] font-normal leading-[7px] flex items-center"
                        >
                          <img
                            src={logoSrc}
                            alt="ClientManager Logo"
                            id="cm_logo"
                            className="mr-[6px] h-8 w-8"
                          />
                          Made with
                          <span className="text-gray-500 font-bold ml-[2px]">
                            ClientManager
                          </span>
                        </a>
                      </div>
                    );
                  } else if (!isCustom && isWhiteLabelUser) {
                    // Case 4: No branding (blank)
                    return null;
                  } else {
                    // Optional: Handle any unexpected cases
                    return null;
                  }
                })()} */}

                <div
                  className={cn(
                    "absolute",
                    !loggedInUser?.white_label_payment ? "w-[145px]" : "w-24"
                  )}
                >
                  {!loggedInUser?.white_label_payment && (
                    <WhiteLabelButton
                      title="Add custom logo"
                      className="uppercase text-[10px]"
                      onClick={() => setIsWhiteLabelFeaturesModalOpen(true)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* invoice */}
        {/* invoice */}
        <div className="custom-wow-alert fixed bottom-[30px] right-[30px] z-300 w-[90px] text-[12px] text-Neutral800">
          Wow, you have a big screen 🙂
        </div>
      </div>

      <FeaturesModal
        isOpen={isWhiteLabelFeaturesModalOpen}
        setIsOpen={setIsWhiteLabelFeaturesModalOpen}
        data={{
          title: "Customise what your clients see...",
          features: [
            "Reward this...",
            'Remove "Made with ClientManager"',
            "Use a custom URL",
            "And more...",
          ],
        }}
      />

      <FollowUpEmailModal
        isOpen={showEmailModal}
        setIsOpen={setShowEmailModal}
        id={invoiceId}
        data={clientDetail?.data}
        type="Invoice"
        selectedData={invoice}
        IsDashboard="detailsPage"
      />
    </>
  );
}

export default Invoices;
