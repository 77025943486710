import axios from "axios";
import { toast } from "react-toastify";
import { getProjectHistoryAction } from "./projectAction";

export const UpdateTimer = () => {
  return async (dispatch) => {
    try {
      return dispatch({
        type: "UPDATE_TIMER",
        payload: null,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return null;
    }
  };
};

export const StartTimer = (data, projectId) => {
  return async (dispatch) => {
    try {
      const { data: res } = await axios.post(
        "/api/v1/time-tracking/start-timer",
        data
      );
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: res.data,
        },
      });
      await dispatch(getProjectHistoryAction(projectId));
      return dispatch({
        type: "START_TIMER",
        payload: data,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return null;
    }
  };
};

export const ResumeTimer = (projectId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/api/v1/time-tracking/resume-timer");
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: data.data,
        },
      });
     await dispatch(getProjectHistoryAction(projectId));
      return dispatch({
        type: "RESUME_TIMER",
        payload: data.data.time_tracker,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return null;
    }
  };
};

export const PauseTimer = (time, projectId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/api/v1/time-tracking/pause-timer", {
        pause_time: time,
      });
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: data.data,
        },
      });
     await dispatch(getProjectHistoryAction(projectId));
      return dispatch({
        type: "PAUSE_TIMER",
        payload: null,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return null;
    }
  };
};

export const StopTimer = (projectId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/api/v1/time-tracking/stop-timer");
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: data.data,
        },
      });
     await dispatch(getProjectHistoryAction(projectId));
      return dispatch({
        type: "STOP_TIMER",
        payload: null,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return null;
    }
  };
};

export const UpdateTimerDetails = (values, projectId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "/api/v1/time-tracking/start-timer",
        values?.data
      );
      dispatch({
        type: "SET_USER_DATA",
        payload: {
          data: data.data,
        },
      });
      if (values?.data?.project) {
        dispatch({
          type: "START_TIMER",
          payload: values,
        });
      }
      await dispatch(getProjectHistoryAction(projectId));
      return dispatch({
        type: "UPDATE_TIMER_DETAILS",
        payload: values,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return null;
    }
  };
};
