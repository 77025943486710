import React from "react";
import Modal from "react-modal";
import CloseBtn from "../../../assets/Images/close-btn.svg";
import ProjectActivity from "../../sections/ProjectHistorySection/ProjectActivity";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
    overflow: "none",
  },
};

const ProjectHistoryModal = ({ isOpen, setIsOpen, project, isModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
    >
      <div className="max-h-[90vh] relative bg-white rounded-lg text-left transform transition-all shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp max-w-[900px] p-6">
        <div
          className="absolute right-0 top-[-40px] z-30 cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseBtn} alt="CloseBtn" />
        </div>
        <div className="flex flex-col h-[600px]">
          <h3 className="text-xl font-semibold text-gray-900 mb-2 ">
            Project Activity
          </h3>
          <div className="border-t mb-2"></div>
          <div className="min-w-[800px] w-full overflow-y-auto">
            <div className="bg-white shadow-sm">
              <ProjectActivity
                project={project}
                setIsOpen={setIsOpen}
                isModal={isModal}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProjectHistoryModal;
