import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import BackNavArrow from "../../../assets/Images/back-nav-arrow.svg";
import OldAndNewValue from "./OldAndNewValue";

/* Formats the createdAt date into a human-readable string */
function formatDate(createdAtDate) {
  const now = moment();
  const date = moment(createdAtDate);
  return now.isSame(date, "day")
    ? `Today at ${date.format("h:mm A")}`
    : `${date.format("DD MMMM YYYY")} at ${date.format("h:mm A")}`;
}

/* Renders a message with specific words styled as links or bold text */
const renderMessageWithHoverableWords = (item) => {
  const words = item.message.split(" ");

  const links = {
    task: `${process.env.REACT_APP_BASE_URL}admin/clients/manage/${item?.client?._id}/project/${item?.project}?cardId=${item?.task}`,
    comment: `${process.env.REACT_APP_BASE_URL}admin/clients/manage/${item?.client?._id}/project/${item?.project}?cardId=${item?.task}`,
    // project: `${process.env.REACT_APP_BASE_URL}admin/clients/manage/${item?.client?._id}/project/${item?.project}`,
    // comment: `${process.env.REACT_APP_BASE_URL}admin/clients/manage/${item?.client?._id}/project/${item?.project}?cardId=${item?.task}`,
  };

  return words.map((word, index) => {
    const link = links[item?.activity_type];
    const isCapitalized =
      word[0] === word[0]?.toUpperCase() && word[0] !== word[0]?.toLowerCase();

    const quotedText = item.message.match(/"([^"]+)"/);
    const isQuoted =
      quotedText && quotedText[1].includes(word.replace(/"/g, ""));

    const isWordAfterOf =
      item.message.includes("of") && item.message.split("of")[1].includes(word);

    if (isWordAfterOf) {
      return (
        <Link key={index} to={link}>
          <span className={`cursor-pointer text-blue-800`}>{word} </span>
        </Link>
      );
    }
    return (
      <span
        key={index}
        className={`${
          isCapitalized ? "font-bold" : "font-normal"
        } text-neutral-800`}
      >
        {word}{" "}
      </span>
    );
  });
};

function ProjectActivity({ project, setIsOpen, isModal }) {
  return (
    <ul>
      {project?.length ? (
        project?.map((item, index) => {
          const isProfileImage = item?.message.includes("ProfileImage");
          const isCategory = item?.message.includes("Category");
          const isStatus = item?.message.includes("Status");
          const isColumn = item?.message.includes("Column");

          const getContentType = () => {
            if (isProfileImage) return "ProfileImage";
            if (isCategory) return "Category";
            if (isStatus) return "Status";
            if (isColumn) return "Column";
            return "default";
          };

          const contentType = getContentType();
          return (
            <li
              key={index}
              className={`flex items-center gap-4 ${
                index === 0 || index === project.length - 1 ? "my-2" : "my-8"
              }`}
            >
              {/* profile image  or fallback avatar */}
              {item?.user?.profile_path ? (
                <img
                  className="!min-w-[36px] w-10 h-10 rounded-full group-hover:bg-indigo-500 bg-blend-overlay group-hover:bg-opacity-50 self-start"
                  src={item?.user?.profile_path}
                  alt="Profile"
                />
              ) : (
                <div
                  className={`border border-Neutral300 ${
                    isModal
                      ? "md:w-[48px] md:h-[48px] h-[48px] w-[48px]"
                      : "md:w-[40px] md:h-[40px] h-[40px] w-[40px]"
                  } flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700  bg-blend-overlay self-start`}
                >
                  <span
                    className={`${
                      isModal
                        ? "text-[20px] 5xl:text-[24px] w-[46px] h-[46px]"
                        : "text-[14px] 5xl:text-[24px]"
                    }  flex items-center justify-center rounded-full font-bold border border-Miscgrey uppercase`}
                  >
                    {item?.user?.user_name?.[0] ||
                      item?.client?.client_name?.[0]}
                  </span>
                </div>
              )}
              <div className="flex-col items-center text-sm align-middle">
                {/* message line and timestamp  */}
                <div className="flex item-center">
                  <p className={`${isModal ? "text-base" : "tetx-sm"} `}>
                    {renderMessageWithHoverableWords(item)}{" "}
                    <span className={`text-neutral-600 font-normal`}>
                      {formatDate(item.createdAt)}
                    </span>
                  </p>
                </div>
                {/* Old to new value changes */}
                <div
                  className={`${
                    isModal ? "text-base" : "text-sm"
                  } pt-1 text-wrap`}
                >
                  <div className="flex items-center gap-2 overflow-wrap-anywhere">
                    <OldAndNewValue
                      type={contentType}
                      value={item?.details?.old}
                    />
                    <img
                      className="self-center rotate-180"
                      src={BackNavArrow}
                      alt="->"
                    />
                    <OldAndNewValue
                      type={contentType}
                      value={item?.details?.new}
                    />
                  </div>
                </div>
              </div>
            </li>
          );
        })
      ) : (
        <div className="text-sm italic font-medium text-Neutral700">
          No project activity yet. Any updates or changes will show here.
        </div>
      )}
    </ul>
  );
}

export default ProjectActivity;
