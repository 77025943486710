import axios from "axios";
import { toast } from "react-toastify";

export const GetAllProjectsActions = ({ page, limit }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_ALL_PROJECTS",
        payload: {
          isLoading: true,
        },
      });
      const { data } = await axios.get(
        `api/v1/project/get-all-projects?page=${page}&limit=${limit}`
      );
      dispatch({
        type: "SET_ALL_PROJECTS_TASKS",
        payload: { data: data.data, isLoading: false },
      });
      return dispatch({
        type: "SET_ALL_PROJECTS",
        payload: { data: data.data, isLoading: false },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "GET_ALL_PROJECTS_ERROR",
        payload: error,
      });
    }
  };
};

export const GetAllProjectsTasksScreenActions = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/project/get-all-projects-screen`
      );
      return dispatch({
        type: "SET_ALL_PROJECTS_TASKS_SCREEN",
        payload: { data: data.data, isLoading: false },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "GET_ALL_PROJECTS_ERROR",
        payload: error,
      });
    }
  };
};

export const GetAllCollabProjectsActions = ({ search }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_ALL_COLLAB_PROJECTS",
        payload: {
          isLoading: true,
        },
      });
      const { data } = await axios.get(
        `api/v1/project/get-client-user-project?search=${search}`
      );
      return dispatch({
        type: "SET_ALL_COLLAB_PROJECTS",
        payload: { data: data.data, isLoading: false },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "GET_ALL_COLLAB_PROJECTS_ERROR",
        payload: error,
      });
    }
  };
};

export const getProjectList = (clientId, isLoading = true) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "GET_PROJECT_LIST_SUCCESS",
        payload: {
          isLoading: isLoading,
        },
      });
      const { data } = await axios.get(
        `api/v1/project/get-project-list?client_id=${clientId}`
      );
      return dispatch({
        type: "GET_PROJECT_LIST_SUCCESS",
        payload: { data: data.data, isLoading: false, clientId },
      });
    } catch (error) {
      return dispatch({
        type: "GET_PROJECT_LIST_ERROR",
        payload: error,
      });
    }
  };
};

export const GetProjectDetailAction = (id, navigate) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/project/get-project-detail?id=${id}`
      );
      return dispatch({
        type: "SET_PROJECT_DETAIL",
        payload: { data: data.data, isLoading: false },
      });
    } catch (error) {
      if (navigate) navigate("/admin/projects");
      return dispatch({
        type: "GET_PROJECT_DETAIL_ERROR",
        payload: error,
      });
    }
  };
};

export const getProjectHistoryAction = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/project/get-project-history?id=${id}`
      );
      return dispatch({
        type: "SET_PROJECT_HISTORY",
        payload: data.data,
      });
    } catch (error) {
      return dispatch({
        type: "GET_PROJECT_DETAIL_ERROR",
        payload: error,
      });
    }
  };
};

export const GetCollabProjectDetailAction = (id, navigate) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/project/get-collab-project-detail?id=${id}`
      );
      return dispatch({
        type: "SET_PROJECT_DETAIL",
        payload: { data: data.data, isLoading: false },
      });
    } catch (error) {
      if (navigate) navigate("/client-collab/all-projects");
      return dispatch({
        type: "GET_PROJECT_DETAIL_ERROR",
        payload: error,
      });
    }
  };
};

export const ShareProjectAction = (values, setIsOpen) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "/api/v1/project-member/invite-client-user",
        values
      );
      if (values?.project) {
        dispatch(GetProjectDetailAction(values?.project));
      }
      toast.success("Client Invited To Project");
      setIsOpen(false);
      return dispatch({
        type: "SET_PROJECT_SHARED",
        payload: { data: data.data, isLoading: false },
      });
    } catch (error) {
      setIsOpen(false);
      if (error?.response?.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        return dispatch({
          type: "SET_PROJECT_SHARED_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_PROJECT_SHARED_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ShareProjectRemoveAccessAction = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "/api/v1/project-member/remove-project-member",
        values
      );
      dispatch({
        type: "UPDATE_PROJECT_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error?.response?.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        return dispatch({
          type: "SET_PROJECT_SHARED_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_PROJECT_SHARED_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ShareProjectChangeAccessAction = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "/api/v1/project-member/update-access-level",
        values
      );
      if (values?.project) {
        dispatch(GetProjectDetailAction(values?.project));
      }
      return dispatch({
        type: "SET_PROJECT_SHARED",
        payload: { data: data, isLoading: false },
      });
    } catch (error) {
      if (error?.response?.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        return dispatch({
          type: "SET_PROJECT_SHARED_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_PROJECT_SHARED_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const CreateNewColumnAction = (
  values,
  navigate,
  setIsOpenColumn,
  setColumn
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/project/create-column", values);
      if (data?.data?.project?._id) {
        if (values?.user_type === "client_user") {
          dispatch(GetCollabProjectDetailAction(data?.data?.project?._id));
        } else {
          dispatch(GetProjectDetailAction(data?.data?.project?._id, navigate));
        }
      }
      dispatch({
        type: "CREATE_PROJECT_COLUMN_SUCCESS",
        payload: { data: data.data },
      });

      if (values?.old_name) {
        if (values?.type === "task") {
          dispatch({
            type: "UPDATE_TASK_COLUMN_NAME",
            payload: { data: data.data },
          });
        }
        if (values?.type === "service") {
          dispatch({
            type: "UPDATE_SERVICE_COLUMN_NAME",
            payload: { data: data.data },
          });
        }
      }
      navigate(``, {
        state: data.data,
        replace: true,
      });
      if (data?.data) {
        if (setIsOpenColumn) await setIsOpenColumn(false);
        if (setColumn) await setColumn("");
      }

      return;
    } catch (error) {
      if (error?.response?.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        return dispatch({
          type: "CREATE_TASK_COLUMN_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_TASK_COLUMN_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ChangeColumnPosition = (values, navigate) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/project/update-column-position",
        values
      );
      dispatch({
        type: "UPDATE_PROJECT_COLUMN_POSITION_SUCCESS",
        payload: { data: data.data },
      });
      navigate(``, {
        state: data.data,
        replace: true,
      });
      return;
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "CREATE_TASK_COLUMN_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_TASK_COLUMN_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const RemoveColumnAction = (values, navigate) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/project/remove-column", values);
      if (data?.data?._id) {
        dispatch(GetProjectDetailAction(data?.data?._id, navigate));
      }
      dispatch({
        type: "REMOVE_PROJECT_COLUMN_SUCCESS",
        payload: { data: data.data },
      });
      navigate(``, {
        state: data.data,
        replace: true,
      });
      return;
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "REMOVE_TASK_COLUMN_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "REMOVE_TASK_COLUMN_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const CreateNewScreenAction = (values, navigate) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/project/add-screen", values);
      dispatch({
        type: "CREATE_PROJECT_SCREEN_SUCCESS",
        payload: { data: data.data },
      });
      navigate(``, {
        state: data.data,
        replace: true,
      });
      return;
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "CREATE_TASK_SCREEN_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_TASK_SCREEN_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const CreateProjectAction = (
  values,
  setSubmitting,
  setErrors,
  setIsOpen,
  navigate
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/project/create-project", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSubmitting(false);
      setIsOpen(false);
      if (data?.data?._id) {
        dispatch(GetProjectDetailAction(data?.data?._id));
        await dispatch(getProjectHistoryAction(data?.data?._id));
      }
      dispatch({
        type: "CREATE_PROJECT_SUCCESS",
        payload: { data: data.data },
      });

      if (navigate) {
        const clientId = values.get("client");
        navigate(
          `/admin/clients/manage/${clientId}/project/${data?.data?._id}`
        );
      }
      return;
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        setErrors(err);
        setSubmitting(false);
        return dispatch({
          type: "CREATE_PROJECT_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "CREATE_PROJECT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const initProject = (value, setIsOpen, navigate, setErrors) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/project/use-custom-template",
        value
      );
      setIsOpen(false);
      if (data.data._id) {
        await dispatch(getProjectHistoryAction(data?.data?._id));
        navigate(
          `/admin/clients/manage/${value?.client_id}/project/${data?.data?._id}`
        );
      } else if (data?.data[0]?._id) {
        await dispatch(getProjectHistoryAction(data?.data[0]?._id));
        navigate(
          `/admin/clients/manage/${value?.client_id}/project/${data.data[0]._id}}`
        );
      }
      toast.success("Project created successfully");
      return dispatch({
        type: "INIT_PROJECT_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        if (setErrors) setErrors(err);
        return dispatch({
          type: "PROJECT_CREATE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_PROJECT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateProjectAction = (
  values,
  setSubmitting,
  setErrors,
  setIsOpen
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/project/update-project", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (setSubmitting) setSubmitting(false);
      if (setIsOpen) setIsOpen(false);
      // if (data?.data?._id) {
      //   dispatch(GetProjectDetailAction(data?.data?._id));
      // }
      await dispatch(getProjectHistoryAction(data?.data?._id));
      dispatch({
        type: "UPDATE_PROJECT_SUCCESS",
        payload: { data: data.data },
      });
      return;
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        if (setErrors) setErrors(err);
        if (setSubmitting) setSubmitting(false);
        return dispatch({
          type: "UPDATE_PROJECT_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        if (setSubmitting) setSubmitting(false);
        return dispatch({
          type: "UPDATE_PROJECT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetArchivedProjectAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_ARCHIVED_PROJECTS_LIST",
        payload: { isLoading: true },
      });
      let { data } = await axios.get(
        `api/v1/project/get-archived-project-list`
      );
      return dispatch({
        type: "SET_ARCHIVED_PROJECTS_LIST",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      return dispatch({
        type: "GET_ARCHIVED_PROJECTS_LIST_GET_ERROR",
        payload: error,
      });
    }
  };
};

export const ArchiveProjectAction = (id, navigate) => {
  return async (dispatch) => {
    try {
      await axios.post(`api/v1/project/archive-project?id=${id}`);
      await dispatch(getProjectHistoryAction(id));
      toast.success("Archived successfully");
      navigate("/admin/tasks");
      return;
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "PROJECT_ARCHIVE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "PROJECT_ARCHIVE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const RestoreArchivedProjectAction = (id, navigate) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/project/restore-project?id=${id}`
      );
      toast.success("Project restored successfully");
      dispatch(GetArchivedProjectAction());
      dispatch(GetAllProjectsActions({ page: 1, limit: 10 }));
      navigate("/admin/tasks");
      await dispatch(getProjectHistoryAction(id));
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "PROJECT_RESTORE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "PROJECT_RESTORE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteProjectAction = (
  id,
  setIsLoading = null,
  navigate = null
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/project/delete-project", {
        id,
      });
      if (setIsLoading)
        setIsLoading({
          loading: false,
          id: null,
        });
      if (navigate) navigate("/admin/tasks");
      dispatch({
        type: "DELETE_PROJECT_SUCCESS",
        payload: { data: data.data },
      });
      toast.success("Project Deleted successfully");
      dispatch(GetArchivedProjectAction());
      return;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
      return dispatch({
        type: "DELETE_PROJECT_ERROR",
        payload: error,
      });
    }
  };
};

export const makeDuplicateProject = (values, navigate) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/project/make-duplicate", values);
      dispatch({
        type: "DUPLICATE_PROJECT_SUCCESS",
        payload: { data: data.data },
      });
      await dispatch(getProjectHistoryAction(data.data._id));
      navigate(``, {
        state: data.data,
        replace: true,
      });
      return;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "DUPLICATE_PROJECT_ERROR",
        payload: error,
      });
    }
  };
};

export const ToggleFavoriteProjectAction = (projectId, newFavoriteStatus) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `api/v1/project/toggle-project-favourite`,
        {
          id: projectId,
          favourite: newFavoriteStatus,
        }
      );

      if (data.success) {
        dispatch({
          type: "UPDATE_PROJECT_FAVORITE",
          payload: { projectId, favorite: data.data.is_favourite },
        });
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      dispatch({
        type: "PROJECT_FAVORITE_TOGGLE_ERROR",
        payload: error,
      });
    }
  };
};
