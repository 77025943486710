import { Menu, Transition } from "@headlessui/react";
import { Formik } from "formik";
import update from "immutability-helper";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { TailwindThemeContext } from "../../App";
import Colour from "../../assets/json/Colour.json";
import Filter from "../../components/filter/filter";
import {
  ChangeColumnPosition,
  CreateNewColumnAction,
} from "../../store/actions/projectAction";
import {
  CreateTaskActions,
  UpdateTaskStatusActions,
} from "../../store/actions/tasksAction";
import PenIcon from "../Icons/PenIcon";
import EditColumnColorsModal from "../Modals/EditColumnColorModal";
import CollabTaskDetailModal from "../Modals/TaskDetailModal/CollabTaskDetailModal.jsx";
import SlideSwitch from "../SlideSwitch.jsx";
import TasksBoxNew from "./Tasks/TasksBoxNew";

const ClientCollabTaskListing = (props) => {
  const {
    clientId,
    allTaskList = [],
    typeIsGeneral,
    isAll,
    types,
    projectId,
    project,
    checkedTask,
    user_type,
    access_level,
    frontFilter,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const cardId = params.get("cardId");
  const isNew = params.get("isNew");
  const newDragRef = useRef(null);
  const { colors } = useContext(TailwindThemeContext);

  const [isGeneral, setIsGeneral] = useState(
    types === "task" ? "GENERAL" : "ONCE"
  );
  const [tasks, setTasks] = useState(allTaskList);
  const [task, setTask] = useState(null);
  const [column, setColumn] = useState("");
  const [isOpenColumn, setIsOpenColumn] = useState(false);
  const [issliderFocus, setIssliderFocus] = useState(false);
  const [filterBtn, setFilterBtn] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const isArchive = false;
  const [isDataGettingSubmitted, setIsDataGettingSubmitted] = useState(false);
  const [addfilter, setAddFilter] = useState({
    urgentonly: [],
    status: [],
    assignedto: [],
    date: [],
    categories: [],
  });
  const [columnsName, setColumnsName] = useState(project?.task_column);
  const [currentColumn, setCurrentColumn] = useState(null);
  const [editColumn, setEditColumn] = useState(null);
  const [isEditColorOpen, setIsEditColorOpen] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const renderColor = () => {
    const newColor = Object.keys(Colour)?.find((ele) => {
      if (types === "task")
        return !project?.task_column?.some((item) => item?.color === ele);
    });
    return newColor;
  };

  useEffect(() => {
    if (typeIsGeneral) {
      setIsGeneral(typeIsGeneral);
    }
  }, [typeIsGeneral]);

  useEffect(() => {
    switch (checkedTask) {
      case 0:
        setAddFilter({
          urgentonly: [],
          status: [],
          assignedto: [],
          date: [],
          categories: [],
        });
        break;
      case 1:
        setAddFilter({
          status: [],
          assignedto: [],
          date: [],
          categories: [],
          urgentonly: ["urgent"],
        });
        break;
      case 2:
        setAddFilter({
          urgentonly: [],
          assignedto: [],
          date: [],
          categories: [],
          status: ["completed"],
        });
        break;
      case 3:
        setAddFilter({
          urgentonly: [],
          assignedto: [],
          date: [],
          categories: [],
          status: ["in_progress"],
        });
        break;
      case 4:
        setAddFilter({
          urgentonly: [],
          assignedto: [],
          date: [],
          categories: [],
          status: ["not_started"],
        });
        break;
      default:
        break;
    }
  }, [checkedTask]);

  useEffect(() => {
    const getfilterdata = allTaskList?.filter((item) => {
      return (
        item?.type === isGeneral &&
        (addfilter.urgentonly?.length
          ? addfilter.urgentonly.includes(item?.priority)
          : true) &&
        (addfilter.status?.length
          ? addfilter.status.includes(item?.tag)
          : true) &&
        (addfilter.assignedto?.length
          ? addfilter?.assignedto?.some((res) =>
              item?.members?.some((rr) => rr._id === res)
            )
          : true) &&
        (addfilter.categories?.length
          ? addfilter?.categories?.some((res) =>
              item?.category?.some((rr) => rr === res)
            )
          : true) &&
        (addfilter?.date?.length
          ? (addfilter?.date.includes("Within 7 Days") &&
              moment()
                .add(7, "days")
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss") > item?.date &&
              moment().format("YYYY-MM-DD") < item?.date) ||
            (addfilter?.date.includes("This Month") &&
              moment().startOf("Month").format("YYYY-MM-DD") <= item?.date &&
              moment().endOf("Month").format("YYYY-MM-DD") >= item?.date) ||
            (addfilter?.date.includes("After this month") &&
              moment().add(1, "M").format("YYYY-MM-DD") > item?.date &&
              moment().endOf("month").format("YYYY-MM-DD") < item?.date)
          : true)
      );
    });
    setTasks(getfilterdata);
  }, [addfilter, allTaskList]);

  const handelSet = () => {
    setIsAnimated(true);
    setTimeout(() => {
      setIsAnimated(false);
      setFilterBtn(!filterBtn);
    }, 350);
  };
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const AddTempTaskToList = (column) => {
    if (!tasks.find((list) => list.new || !list.title.trim().length)) {
      setTask({
        _id: generateString(12),
        description: null,
        column: column,
        title: "",
        type: isGeneral,
      });
      setIssliderFocus(true);
    }
  };

  const handleChangeTitle = (event) => {
    setTask({
      ...task,
      title: event.target.value,
    });
  };

  const handleSubmits = async () => {
    if (task?.title !== "") {
      if (types === "task") {
        if (!isDataGettingSubmitted) {
          setIsDataGettingSubmitted((prev) => {
            return !prev;
          });
          await dispatch(
            CreateTaskActions({
              ...task,
              client_id: clientId,
              position: tasks.length === 0 ? 0 : tasks.length,
              project: projectId,
              user_type: "client_user",
            })
          );
          setIsDataGettingSubmitted((prev) => {
            return !prev;
          });
        }
      }
      setIssliderFocus(false);
      setTask(null);
    } else {
      const filterWithoutTitle = tasks?.filter((item) => item?.title !== "");
      setTasks(filterWithoutTitle);
      setTask(null);
    }
  };

  const handleAddColumn = async () => {
    if (column && column !== "") {
      if (types === "task") {
        await dispatch(
          CreateNewColumnAction(
            {
              id: clientId,
              column: { name: column, color: renderColor() },
              type: types,
              project: projectId,
              user_type: "client_user",
            },
            navigate,
            setIsOpenColumn,
            setColumn
          )
        );
      }
    }
    setIsOpenColumn(false);
    setColumn("");
  };

  useEffect(() => {
    if (frontFilter) {
      if (frontFilter.length === 0) {
        let AllTask = allTaskList?.filter((list) => list?.type === isGeneral);
        setTasks([...AllTask]);
      } else {
        const data = allTaskList?.filter(
          (list) => list?.column === frontFilter && list?.type === isGeneral
        );
        setTasks(data);
      }
    } else {
      let getAllData = allTaskList?.filter((list) => list?.type === isGeneral);
      setTasks([...getAllData]);
    }
  }, [isGeneral, allTaskList, frontFilter]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const changeTaskStatus = useCallback(
    (
      id,
      column,
      sourceIndex,
      destinationIndex,
      isCMDefaultColumn,
      dropContext
    ) => {
      let task = tasks.find((task) => task._id === id);
      const taskIndex = tasks.indexOf(task);
      // change task tag of client have default columns
      if (isCMDefaultColumn && types === "task") {
        if (column === "To Do") {
          task = { ...task, tag: "not_started" };
        } else if (column === "Doing") {
          task = { ...task, tag: "in_progress" };
        } else if (column === "Done") {
          task = { ...task, tag: "completed" };
        }
      }
      task = { ...task, column };
      let newTasks = update(tasks, {
        [taskIndex]: { $set: task },
      });
      return reorder(newTasks, sourceIndex, destinationIndex);
    },
    [tasks]
  );

  function handleOnDragEnd(result) {
    const old_data = tasks;
    // checking whether the client have CM default column or not
    const CMColumns = columnsName.filter((item) =>
      ["To Do", "Doing", "Done"].includes(item?.name)
    );
    const isCMDefaultColumn = CMColumns?.length === 3;
    if (!result.destination) return;
    const { source, destination } = result;
    let content;
    if (source.droppableId === destination.droppableId) {
      content = reorder(tasks, result.source.index, result.destination.index);
    } else {
      content = changeTaskStatus(
        result.draggableId,
        result.destination.droppableId,
        result.source.index,
        result.destination.index,
        isCMDefaultColumn,
        result
      );
    }
    const getSpecificField = content.map((item, index) => {
      return {
        ...item,
        position: index,
      };
    });
    const updatedPosition = content.map((item, index) => {
      return {
        _id: item?._id,
        column: item?.column,
        tag: item?.tag,
        position: index,
        user_type: "client_user",
      };
    });
    setTasks(getSpecificField);
    if (types === "task") {
      dispatch(
        UpdateTaskStatusActions(
          {
            tasks: updatedPosition,
            user_type: user_type,
            taskId: result.draggableId,
            oldTag: result.source.droppableId,
            newTag: result.destination.droppableId,
          },
          getSpecificField,
          old_data,
          {
            id: clientId,
            project: projectId,
          }
        )
      );
    }
  }

  useEffect(() => {
    setColumnsName(project?.task_column);
  }, [project]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "column") {
      const dragColumn = columnsName.find((item) => item?._id === draggableId);
      const newColumnOrder = Array.from(columnsName);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, dragColumn);
      setColumnsName(newColumnOrder);
      if (types === "task") {
        dispatch(
          ChangeColumnPosition(
            {
              id: clientId,
              columns: newColumnOrder,
              type: types,
              project: projectId,
              user_type: "client_user",
            },
            navigate
          )
        );
      }
      return;
    }
    handleOnDragEnd(result);
  };

  const onApplyColor = async (
    column,
    type = "Default",
    color = "",
    name,
    setIsOpen
  ) => {
    const colIdx = columnsName.findIndex((item) => item?._id === column?._id);
    if (colIdx > -1) {
      setColumnsName((columnsName) => {
        if (type === "Default") {
          return update(columnsName, {
            [colIdx]: {
              color: {
                $set: color,
              },
            },
          });
        } else {
          return update(columnsName, {
            [colIdx]: {
              color: {
                $set: color,
              },
              name: {
                $set: name,
              },
            },
          });
        }
      });
    }
    if (types === "task") {
      await dispatch(
        CreateNewColumnAction(
          {
            id: clientId,
            column: {
              ...column,
              color,
              name,
            },
            old_name: column?.name,
            type: types,
            project: projectId,
            user_type: "client_user",
          },
          navigate,
          setIsOpen
        )
      );
    }
  };
  return (
    <div className="5xl:px-[96px] pt-0">
      <div className="flex items-center gap-2 mt-5 text-xs">
        <span
          className={`font-medium ${
            !enabled ? "text-AccentRegular" : "text-Neutral700"
          }`}
        >
          Simplified Tasks
        </span>
        <SlideSwitch checked={enabled} onChange={setEnabled} />
        <span
          className={`font-medium ${
            enabled ? "text-AccentRegular" : "text-Neutral700"
          }`}
        >
          Expanded Tasks
        </span>
      </div>

      <div
        className={`mt-5 overflow-auto flex ${
          isAll && "bg-Neutral100 p-5"
        } pr-0 pb-5 scrollbar-h-4`}
      >
        <DragDropContext onDragEnd={onDragEnd} ref={newDragRef}>
          <Droppable
            droppableId="all-columns"
            direction="horizontal"
            type="column"
          >
            {(provided) => (
              <div
                className="flex gap-3"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {columnsName?.map((channel, index) => {
                  const this_col_color = Colour[channel?.color];
                  const columnTasks = tasks
                    ?.sort((a, b) => a?.position - b?.position)
                    .filter((item) => item?.column === channel?.name)
                    .filter((item) => (isArchive ? item : !item?.is_archived));
                  return (
                    <Draggable
                      draggableId={channel?._id}
                      index={index}
                      key={channel?._id}
                      isDragDisabled={access_level !== "full_access"}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          className="flex flex-col bg-white rounded-[8px]"
                        >
                          <div
                            {...provided.dragHandleProps}
                            className={`5xl:max-w-[400px] max-w-[288px] min-w-[288px]  w-full border h-fit p-[12px]`}
                            style={{
                              ...(isAll
                                ? { border: "none", padding: 0 }
                                : {
                                    borderColor: this_col_color?.border
                                      ? `${colors[this_col_color?.border]}${
                                          this_col_color?.opacity
                                        }`
                                      : `${channel?.color + "01"}`,
                                    backgroundColor: this_col_color?.background
                                      ? `${colors[this_col_color?.background]}${
                                          this_col_color?.opacity
                                        }`
                                      : `${channel?.color + "03"}`,
                                  }),
                            }}
                          >
                            <div
                              className={`flex ${
                                isAll ? "" : "justify-between"
                              } mb-4`}
                            >
                              <h3
                                className={`text-${
                                  this_col_color?.color
                                } text-xs font-bold uppercase align-center ${
                                  isAll
                                    ? `bg-${this_col_color?.headingBackground} text-${this_col_color?.headingColor} flex justify-center py-[6px] rounded 5xl:max-w-[400px] max-w-[288px] min-w-[288px] w-full`
                                    : ""
                                }`}
                                style={{
                                  ...(isAll
                                    ? {
                                        borderColor: `${
                                          colors[this_col_color?.border]
                                        }${this_col_color?.opacity}`,
                                        backgroundColor:
                                          this_col_color?.headingBackground
                                            ? `${
                                                colors[
                                                  this_col_color
                                                    ?.headingBackground
                                                ]
                                              }${this_col_color?.opacity}`
                                            : `${channel?.color + "08"}`,
                                        color: this_col_color?.headingColor
                                          ? `${
                                              colors[
                                                this_col_color?.headingColor
                                              ]
                                            }`
                                          : `${channel?.color}`,
                                      }
                                    : !this_col_color
                                    ? {
                                        color: channel?.color,
                                      }
                                    : {}),
                                }}
                              >
                                <span
                                  className={`text-${this_col_color?.color} ${
                                    isAll
                                      ? `text-${this_col_color?.headingColor} tracking-wider`
                                      : ""
                                  }`}
                                >
                                  {channel?.name}
                                </span>{" "}
                                {!isAll && <span>{columnTasks?.length}</span>}
                              </h3>
                              {access_level === "full_access" && (
                                <div
                                  className={`flex items-center justify-end ml-[-5%]`}
                                >
                                  <Menu
                                    as="div"
                                    className="relative inline-block"
                                  >
                                    <div className="flex items-center">
                                      <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none">
                                        <span className="focus-visible:outline-none">
                                          <svg
                                            width="3"
                                            height="10"
                                            viewBox="0 0 3 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1.5 5.5C1.77614 5.5 2 5.27614 2 5C2 4.72386 1.77614 4.5 1.5 4.5C1.22386 4.5 1 4.72386 1 5C1 5.27614 1.22386 5.5 1.5 5.5Z"
                                              className={`stroke-${channel?.color}600 fill-${channel?.color}600`}
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              style={
                                                !this_col_color
                                                  ? {
                                                      stroke: channel?.color,
                                                      fill: channel?.color,
                                                    }
                                                  : {}
                                              }
                                            />
                                            <path
                                              d="M1.5 2C1.77614 2 2 1.77614 2 1.5C2 1.22386 1.77614 1 1.5 1C1.22386 1 1 1.22386 1 1.5C1 1.77614 1.22386 2 1.5 2Z"
                                              className={`stroke-${channel?.color}600 fill-${channel?.color}600`}
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              style={
                                                !this_col_color
                                                  ? {
                                                      stroke: channel?.color,
                                                      fill: channel?.color,
                                                    }
                                                  : {}
                                              }
                                            />
                                            <path
                                              d="M1.5 9C1.77614 9 2 8.77614 2 8.5C2 8.22386 1.77614 8 1.5 8C1.22386 8 1 8.22386 1 8.5C1 8.77614 1.22386 9 1.5 9Z"
                                              className={`stroke-${channel?.color}600 fill-${channel?.color}600`}
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              style={
                                                !this_col_color
                                                  ? {
                                                      stroke: channel?.color,
                                                      fill: channel?.color,
                                                    }
                                                  : {}
                                              }
                                            />
                                          </svg>
                                        </span>
                                      </Menu.Button>
                                    </div>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="z-10 absolute p-1 min-w-[129px] rounded-[4px] right-0 Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none innermenu-main">
                                        <Menu.Item>
                                          <div
                                            className="w-[100%] text-left pl-2 py-2 text-xs whitespace-nowrap flex justify-start items-center cursor-pointer text-Neutral800 font-semibold transition duration-300 hover:text-AccentRegular hover:fill-AccentRegular"
                                            onClick={() => {
                                              setCurrentColumn(channel);
                                              setEditColumn(channel);
                                              setIsEditColorOpen(true);
                                            }}
                                          >
                                            {/* <img
                                                src={Duplicate}
                                                className="mr-[8px]"
                                              />
                                              Duplicate */}
                                            <PenIcon
                                              width={16}
                                              height={16}
                                              className="flex items-center justify-center mr-2"
                                              color="currentColor"
                                            />
                                            <span className="text-xs leading-[16px] tracking-[0.04em] font-medium">
                                              Edit Column
                                            </span>
                                          </div>
                                        </Menu.Item>
                                        {/* {(types === "task" && 
                                          columnsName?.length > 3) 
                                         ? (
                                          <Menu.Item>
                                            <Menu
                                              as="div"
                                              className="relative"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                              }}
                                            >
                                              <div className="">
                                                <Menu.Button className="w-full focus-visible:outline-none">
                                                  <span className="focus-visible:outline-none">
                                                    <div className="w-[100%] text-left pl-2 py-2 text-xs whitespace-nowrap flex justify-start items-center cursor-pointer text-Neutral800 font-semibold hover:bg-Neutral200 transition duration-300 hover:text-Neutral900 edite-hover">
                                                      <img
                                                        src={Delete}
                                                        className="mr-2"
                                                      />
                                                      Delete
                                                    </div>
                                                  </span>
                                                </Menu.Button>
                                              </div>
                                              <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                              >
                                                <Menu.Items
                                                  className="origin-top-right absolute -top-[80px] right-[-20px] pt-4 pb-6 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none"
                                                  onClick={(e) => {
                                                    if (
                                                      e.target ===
                                                      e.currentTarget
                                                    ) {
                                                      e.stopPropagation();
                                                    }
                                                  }}
                                                >
                                                  <div className="">
                                                    <p className="pb-2 text-xs font-semibold text-Neutral700">
                                                      Are you sure?
                                                    </p>
                                                  </div>
                                                  <div
                                                    className="overflow-hidden border-0"
                                                    onClick={async () => {
                                                      if (types === "task") {
                                                        await dispatch(
                                                          RemoveColumnAction(
                                                            {
                                                              id: clientId,
                                                              remove_column:
                                                                channel,
                                                              type: types,
                                                              project:
                                                                projectId,
                                                            },
                                                            navigate
                                                          )
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <h3 className="w-full py-4 mt-5 text-xs font-semibold text-center text-white duration-300 rounded cursor-pointer bg-Red400 hover:bg-Red600">
                                                      Yes, Delete
                                                    </h3>
                                                  </div>
                                                  <div className="overflow-hidden border-0 !border-transparent divide-0">
                                                    <h3 className="w-full py-4 mt-3 text-xs font-semibold text-center duration-300 rounded cursor-pointer text-Neutral800 bg-Neutral200 hover:bg-Neutral500 hover:text-Neutral900">
                                                      No, Please Keep It
                                                    </h3>
                                                  </div>
                                                </Menu.Items>
                                              </Transition>
                                            </Menu>
                                          </Menu.Item>
                                        ) : (
                                          <div />
                                        )} */}
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </div>
                              )}
                            </div>
                            <Droppable droppableId={channel?.name}>
                              {(provided) => (
                                <div
                                  className="dropped-container min-h-[1px]"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  {columnTasks?.map((item, i) => {
                                    return (
                                      <Draggable
                                        key={item?._id}
                                        draggableId={item?._id}
                                        index={item?.position}
                                        isDragDisabled={
                                          access_level !== "full_access"
                                        }
                                      >
                                        {(provided) => (
                                          <div
                                            className="list-none drop-list-item"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onClick={() =>
                                              setCurrentColumn(channel)
                                            }
                                          >
                                            <TasksBoxNew
                                              item={item}
                                              enabled={enabled}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                            {task &&
                              task?.column === channel?.name &&
                              access_level === "full_access" && (
                                <div
                                  className={`border border-Neutral300 bg-white py-3 pl-4 pr-1 rounded-[6px] relative cursor-pointer mb-4 ${
                                    issliderFocus
                                      ? "tasklists-focus drop-shadow-Purpleboxshadow"
                                      : ""
                                  }`}
                                >
                                  <Formik
                                    initialValues={{
                                      text: "",
                                    }}
                                    onSubmit={handleSubmits}
                                  >
                                    {({ handleSubmit, handleChange }) => (
                                      <form
                                        onSubmit={handleSubmit}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            handleSubmit();
                                          }
                                        }}
                                      >
                                        <textarea
                                          name="text"
                                          placeholder={"Add task"}
                                          onChange={(event) => {
                                            handleChangeTitle(event);
                                            handleChange(event);
                                          }}
                                          className="text-Neutral800 text-sm pr-[76px] content 5xl:max-w-[352px] w-full focus-visible:outline-none resize-none "
                                          onBlur={() => handleSubmits()}
                                          autoFocus
                                        />
                                      </form>
                                    )}
                                  </Formik>
                                </div>
                              )}
                            {access_level === "full_access" && (
                              <button
                                className={`flex justify-center items-center py-[14px] pl-4 pr-1 border-[1px] border-dashed border-${this_col_color?.dashBorder} rounded-lg cursor-pointer  w-full`}
                                onClick={() => AddTempTaskToList(channel?.name)}
                                style={
                                  !this_col_color
                                    ? {
                                        borderColor: channel?.color,
                                      }
                                    : {}
                                }
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.6569 8H2.34315"
                                    className={`stroke-${channel?.color}600`}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={
                                      !this_col_color
                                        ? {
                                            stroke: channel?.color,
                                          }
                                        : {}
                                    }
                                  />
                                  <path
                                    d="M8 2.34315V13.6569"
                                    className={`stroke-${channel?.color}600`}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={
                                      !this_col_color
                                        ? {
                                            stroke: channel?.color,
                                          }
                                        : {}
                                    }
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {(isOpenColumn ||
          (types === "task" &&
            project?.task_column?.length < 6 &&
            access_level === "full_access")) && (
          <div className="5xl:max-w-[400px] max-w-[288px] min-w-[288px] min-h-[300px] mx-5 2xl:mr-0">
            {isOpenColumn ? (
              <div
                className={`5xl:max-w-[400px] max-w-[288px] min-w-[288px] w-full rounded-[6px] bg-${
                  Colour[renderColor()]?.background
                }/[0.1] border border-${
                  Colour[renderColor()]?.border
                } h-fit p-[12px] mr-[20px]`}
              >
                <div className="flex justify-between mb-4">
                  <input
                    name="text"
                    placeholder="Add column"
                    value={column}
                    onChange={(event) => {
                      setColumn(event.target.value);
                    }}
                    className={`text-${
                      Colour[renderColor()]?.color
                    } text-xs font-bold focus:outline-none w-auto bg-${
                      Colour[renderColor()]?.background
                    }/[0.1]`}
                    onBlur={() => {
                      handleAddColumn();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleAddColumn();
                      }
                    }}
                    autoFocus={isOpenColumn}
                  />
                </div>
                <button
                  className={`flex justify-start items-baseline w-full py-[14px] pl-4 pr-1 border-[1px] border-dashed border-${
                    Colour[renderColor()]?.dashBorder
                  } rounded-lg cursor-pointer`}
                >
                  <div
                    className={`font-semibold text-sm max-w-[100%] w-full text-center text-${
                      Colour[renderColor()]?.iconColor
                    } cursor-pointer`}
                  >
                    +
                  </div>
                </button>
              </div>
            ) : (
              <button
                className={`flex justify-start items-baseline 5xl:max-w-[400px] max-w-[288px] min-w-[288px] w-full py-[14px] pl-4 pr-1 border-[1px] border-dashed border-${
                  Colour[renderColor()]?.dashBorder
                } rounded-lg cursor-pointer`}
                onClick={() => {
                  setIsOpenColumn(true);
                }}
              >
                <div
                  className={`font-semibold text-sm max-w-[100%] w-full text-center text-${
                    Colour[renderColor()]?.iconColor
                  } cursor-pointer`}
                >
                  +
                </div>
              </button>
            )}
          </div>
        )}
      </div>
      {cardId && !isNew ? (
        <CollabTaskDetailModal
          clientId={clientId}
          types={types}
          projectId={projectId}
          columnsName={columnsName}
          column={currentColumn}
          project={project}
          user_type={user_type}
          access_level={access_level}
        />
      ) : (
        <div />
      )}
      <Filter
        filterBtn={filterBtn}
        setFilterBtn={setFilterBtn}
        handelSet={handelSet}
        isAnimated={isAnimated}
        setIsAnimated={setIsAnimated}
        addfilter={addfilter}
        setAddFilter={setAddFilter}
      />
      <EditColumnColorsModal
        isOpen={isEditColorOpen}
        setIsOpen={setIsEditColorOpen}
        editColumn={editColumn}
        onApplyColor={onApplyColor}
      />
    </div>
  );
};

export default ClientCollabTaskListing;
